let _hsq;

export const initHubspotAnalytics = () => {
  _hsq = typeof window !== 'undefined' && window._hsq ? window._hsq : [];
}

export const setContentType = (contentType) => {
  _hsq.push(['setContentType', contentType])
}

const setTrackPageView = () => {
  _hsq.push(['trackPageView']);
}

export const setPathPageView = (path) => {
  _hsq.push(['setPath', path])
  // This function track the current updated page path
  setTrackPageView()
}

export const setIdentity = (email, customProperties) => {
  _hsq.push([
    'identify',
    {
      email,
      ...customProperties
    }
  ]);
}

export const setTrackEvent = ({ eventId, value }) => {
  _hsq.push([
    'trackEvent',
    {
      id: eventId,
      value
    }
  ]);
}
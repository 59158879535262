import React, { useState } from 'react';
import classes from './add-notes.module.scss';
import { Button, MenuItem, TextField } from '@mui/material';
import { CustomSelect } from '../../components/CustomFields';
import { useParams } from 'react-router-dom';
import { createProjectTag } from '../../api/user';
import { hasValues } from '../../utils/utils';
import { notify } from '../../components/Snackbar';
import { tagColors } from '../research-dashboard/module';
import { addProjectTagAction } from '../../store/reducers/user';
import { useDispatch } from 'react-redux';

export function CreateTag({ title = 'Create tag', onSubmit }) {
  const initialForm = {
    name: '',
    color: ''
  };

  const [form, setForm] = useState(initialForm);
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const getFormProps = (name) => ({
    bg: 'gray',
    size: 'xs',
    name: name,
    value: form[name],
    className: classes.field,
    onChange: handleFormChange
  });

  const handleCreateTag = () => {
    if (!hasValues(form)) return notify.warning('All fields are required!');
    createProjectTag({ projectId, ...form }).then((tag) => {
      dispatch(addProjectTagAction({ projectId, ...tag }));
      setForm(initialForm);
    });
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{title}</div>
        </div>
        <div className={classes.body}>
          <div className={`${classes.item} ${classes.comment}`}>
            <div className={classes.label}>Name</div>
            <TextField placeholder="Enter comment" {...getFormProps('name')} />
          </div>
          <div className={classes.item}>
            <div className={classes.label}>Tag</div>
            <CustomSelect
              placeholder="Select tag colour"
              {...getFormProps('color')}
            >
              {tagColors.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            disableElevation
            className={`${classes.save} ml-auto`}
            onClick={handleCreateTag}
          >
            Create tag
          </Button>
        </div>
      </div>
    </div>
  );
}

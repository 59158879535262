import React, { useState } from 'react';
import { ReactComponent as Emoji } from '../../assets/svg/Emoji.svg';
import classes from './research-dashboard.module.scss';
import { Link, Button } from '@mui/material';
import { FirstProjectNoExtracts } from '../welcome-page/FirstProjectNoExtracts';
import { useSelector } from 'react-redux';

export function NoExtracts() {
  const firstLogIn = useSelector(({ user }) => user.firstLogIn);
  const [openText, setOpenText] = useState(false);

  const handleOpenText = () => {
    setOpenText(!openText);
  };

  const handleGoStore = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/analystpro/gnbadckbnacgkondohadcpejpmefkcee?hl=en&authuser=0'
    );
  };
  const handleGoYoutube = () => {
    window.open('https://vimeo.com/733680567');
  };

  if (firstLogIn) {
    return <FirstProjectNoExtracts />;
  } else {
    return (
      <div className={classes.noExtracts}>
        <div className={classes.mb36}>
          <Emoji />
        </div>

        <div>
          <div className={`${classes.title} ${classes.mb36}`}>
            It looks like you haven't saved any research yet
          </div>
          <div className={`${classes.text} ${classes.mb36}`}>
            Your research will be saved, structured and organised into your
            chosen project here.{' '}
            <Link component="button" onClick={handleOpenText}>
              {!openText ? `Show hints` : `Show less`}
            </Link>
          </div>
          {openText ? (
            <div>
              <div className={`${classes.text} ${classes.mb16}`}>
                You can easily save data and add notes by:
              </div>

              <ol>
                <li className={`${classes.text} ${classes.mb16}`}>
                  <b>Install</b> - Make sure you have downloaded the AnalystPro
                  <Link onClick={handleGoStore} component="button">
                    {' '}
                    browser extension
                  </Link>{' '}
                  and that you are logged in.
                </li>

                <li className={`${classes.text} ${classes.mb16}`}>
                  <b>Search</b> - Browse the web to find content you would like
                  to save. This can be a web page, text extract, image, or a
                  PDF.
                </li>

                <li className={`${classes.text} ${classes.mb16}`}>
                  <b>Choose</b> - Highlight your chosen content. Click our
                  AnalystPro Icon that pops up. Or right click and “Save to
                  AnalystPro”.
                </li>

                <li className={`${classes.text} ${classes.mb16}`}>
                  <b>Save</b> - Save your selected data to your chosen project.
                </li>
              </ol>
              <div className={`${classes.text} ${classes.mb36}`}>
                <b> AnalystPro tip:</b> Add comments and tags to give your
                research more structure.
              </div>
            </div>
          ) : null}
          <Button
            type="submit"
            onClick={handleGoYoutube}
            className="w-100 mb-36"
          >
            Show me how
          </Button>
        </div>
      </div>
    );
  }
}

import { Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './first-project.module.scss';
import { setFirstLogIn } from '../../store/reducers/user';

export function FirstProjectNoExtracts() {
  const dispatch = useDispatch();
  const updateFirstLogIN = () => {
    dispatch(setFirstLogIn(false));
  };

  const handleGoYoutube = () => {
    window.open('https://vimeo.com/733680567');
  };
  return (
    <div className={classes.noExtracts}>
      <div className={classes.mainTitle}>Begin research (4/4)</div>
      <div className={`${classes.textExtracts} ${classes.mb16}`}>
        Your research will be saved, structured and organised into your chosen
        project here.
      </div>
      <div className={`${classes.textExtractsBold} ${classes.mb16}`}>
        You can easily save data and add notes
      </div>
      <ol>
        <li className={`${classes.textExtracts} ${classes.mb16}`}>
          <b>Search</b> - Browse the web to find content you would like to save.
          This can be a web page, text extract, image, or a PDF.
        </li>
        <li className={`${classes.textExtracts} ${classes.mb16}`}>
          <b>Choose</b> - Highlight your chosen content. Click our AnalystPro
          icon that pops up. Or right click and “Save to AnalystPro”.
        </li>
        <li className={classes.textExtracts}>
          <b>Save</b> - Save your selected data to your chosen project.
        </li>
      </ol>
      <div className={`${classes.textExtracts} ${classes.mb36}`}>
        <b>AnalystPro tip:</b> add comments and tags to give your research more
        insight and structure.
      </div>

      <Button type="submit" className="w-100 mb-36" onClick={handleGoYoutube}>
        Show me how
      </Button>
      <Button variant="outlined" className="w-100" onClick={updateFirstLogIN}>
        I’ll figure it out
      </Button>
    </div>
  );
}

import React, { useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { resetPasswordForm } from './module';
import { resetUserPassword } from '../../api/auth';
import { notify } from '../../components/Snackbar';
import { hasValues } from '../../utils/utils';

export function ResetPasswordForm({ token, onSubmit }) {
  const {
    values,
    errors,
    setValues,
    handleChange,
    validateField,
    handleSubmit
  } = useFormik({
    ...resetPasswordForm,
    onSubmit: (values) => {
      if (!hasValues(values)) return notify.warning('All fields are required!');
      resetUserPassword({
        secret: values.password,
        token
      }).then(() => {
        setValues(resetPasswordForm.initialValues);
        notify.success('Success!');
        onSubmit();
      });
    }
  });

  useEffect(() => {
    if (values.repeat) {
      validateField('repeat');
    }
  }, [values.password]);

  async function handlePasswordChange(e) {
    const { name } = e.target;
    await handleChange(e);
    await validateField(name);
  }

  return (
    <div className="main">
      <div className="container">
        <h2 className="title text-center mb-1">Reset Password</h2>

        <TextField
          name="password"
          type="password"
          placeholder="New password"
          className="w-100 mb-1"
          value={values.password}
          onChange={handlePasswordChange}
          error={!!errors.password}
          helperText={errors.password}
        />

        <TextField
          name="repeat"
          type="password"
          placeholder="Repeat password"
          className="w-100 mb-1"
          value={values.repeat}
          onChange={handlePasswordChange}
          error={!!errors.repeat}
          helperText={errors.repeat}
        />

        <Button type="submit" className="w-100 mb-1" onClick={handleSubmit}>
          Reset Password
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import { Button } from '@mui/material';

export function Welcome(props) {
  const { setCurrent } = props;
  const installExtension = 1;

  const firstStep = () => setCurrent(installExtension);

  return (
    <div className="main centered">
      <div className="container-getting-started">
        <h2 className="title-welcome text-center mb-1 w-100">Welcome</h2>
        <p className="text text-left mb-1 ml-6">
          Thank you for signing up to AnalystPro.
          <br />
          Your first step to boost your research.
        </p>
        <h3 className="welcome-second-title text-center mb-1">
          Get started in 4 easy steps
        </h3>
        <div className="text-center">
          <Button className="w-100" onClick={firstStep}>
            Let’s go
          </Button>
        </div>
      </div>
    </div>
  );
}

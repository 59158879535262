import React, { useState } from 'react';
import { Link, Button } from '@mui/material';
import { ReactComponent as Emoji } from '../../assets/svg/Emoji.svg';
import classes from './research-dashboard.module.scss';
import { FirstProjectNoVariables } from '../welcome-page/FirstProjectNoVariables';
import { useSelector } from 'react-redux';

export function NoVariables({ onClick }) {
  const firstLogIn = useSelector(({ user }) => user.firstLogIn);
  const [openText, setOpenText] = useState(false);

  const handleOpenText = () => {
    setOpenText(!openText);
  };

  const handleGoYoutube = () => {
    window.open('https://vimeo.com/733680567');
  };

  if (firstLogIn) {
    return <FirstProjectNoVariables />;
  } else {
    return (
      <div className={classes.noVariables}>
        <div className={classes.mb36}>
          <Emoji />
        </div>
        <div>
          <div className={`${classes.title} ${classes.mb36}`}>
            It looks like you don’t have any values yet
          </div>
          <div className={`${classes.text} ${classes.mb36}`}>
            Values are quantitative data. Creating a value from the original
            source will make it easier to save, organise and trace these data.{' '}
            <Link component="button" onClick={handleOpenText}>
              {!openText ? `Show hints` : `Show less`}
            </Link>
          </div>
          {openText ? (
            <div>
              <div className={`${classes.text} ${classes.mb16}`}>
                You can create a value by:
              </div>
              <ul>
                <li className={`${classes.text} ${classes.mb16} `}>
                  <b>Browser extension</b> - Directly through your browser
                  extension by using Add Notes and then clicking Create Value.
                </li>
                <li className={`${classes.text} ${classes.mb16} `}>
                  <b>Web app</b> - Through the web app by clicking Create Value
                  and referencing a saved extract in your project.
                </li>
              </ul>
              <div className={`${classes.text} ${classes.mb36}`}>
                <b>AnalystPro tip:</b> Export the values to use them directly in
                your Excel model to retain a traceable source of truth.
              </div>
            </div>
          ) : null}
        </div>
        <Button type="submit" className="w-100 mb-36" onClick={onClick}>
          Create value
        </Button>
        <Button variant="outlined" className="w-100" onClick={handleGoYoutube}>
          Show me how
        </Button>
      </div>
    );
  }
}

import { lsWipeData, lsSetUser } from '../../utils/utils';
import { initUserAxiosInstance } from '../../utils/axios';

const defaultState = {
  invitation: undefined,
  firstLogIn: false,
  email: null,
  name: null,
  token: null,
  role: null,
  company: null,
  projects: [],
  researches: [],
  variables: []
};

const LOG_IN = 'LOG_IN';
const LOG_OUT = 'LOG_OUT';
const SET_EMAIL = 'SET_EMAIL';
const SET_INVITATION = 'SET_INVITATION';
const SET_USER = 'SET_USER';

const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
const SET_PROJECTS = 'SET_PROJECTS';
const SET_RESEARCHES = 'SET_RESEARCHES';
const SET_VARIABLES = 'SET_VARIABLES';
const UPDATE_VARIABLES = 'UPDATE_VARIABLES';
const ADD_PROJECT_TAG = 'ADD_PROJECT_TAG';
const UPDATE_VARIABLE = 'UPDATE_VARIABLE';
const UPDATE_RESEARCH = 'UPDATE_RESEARCH';

export function logInAction(payload, dispatch) {
  lsSetUser(payload);
  initUserAxiosInstance({
    token: payload.token,
    logoutAction: () => dispatch(logOutAction())
  });
  return { type: LOG_IN, payload };
}

export function logOutAction() {
  lsWipeData();
  return { type: LOG_OUT };
}

export const setUserAction = (payload) => ({ type: SET_USER, payload });
export const setEmailAction = (payload) => ({ type: SET_EMAIL, payload });
export const setInvitationAction = (payload) => ({
  type: SET_INVITATION,
  payload
});

export const setFirstLogIn = (payload) => ({ type: SET_FIRST_LOGIN, payload });
export const setProjectsAction = (payload) => ({ type: SET_PROJECTS, payload });
export const setResearchesAction = (payload) => ({
  type: SET_RESEARCHES,
  payload
});

export const setVariablesAction = (payload) => ({
  type: SET_VARIABLES,
  payload
});

export const updateVariablesAction = (payload) => ({
  type: UPDATE_VARIABLES,
  payload
});

export const addProjectTagAction = (payload) => ({
  type: ADD_PROJECT_TAG,
  payload
});

const addNewProjectTag = ({ projects }, payload) => {
  const { projectId, id, name, color } = payload;
  const currentProject = projects.find((p) => p.id == projectId);
  currentProject.tags.push({ id, name, color });
  return [...projects];
};

export const updateVariableAction = (payload) => ({
  type: UPDATE_VARIABLE,
  payload
});

export const updateResearchAction = (payload) => ({
  type: UPDATE_RESEARCH,
  payload
});

const updateById = (iterable, payload, merge = true) => {
  const id = iterable.findIndex((v) => v.id === payload.id);
  if (id === -1) return iterable;
  if (merge) iterable[id] = { ...iterable[id], ...payload };
  else iterable[id] = payload;
  return [...iterable];
};

export function userReducer(state = defaultState, action) {
  switch (action.type) {
    case LOG_IN:
      return { ...state, ...action.payload };
    case LOG_OUT:
      return { ...defaultState };
    case SET_EMAIL:
      return { ...state, email: action.payload };
    case SET_INVITATION:
      return { ...state, invitation: action.payload };
    case SET_USER:
      return { ...state, ...action.payload };
    case SET_FIRST_LOGIN:
      return { ...state, firstLogIn: action.payload };
    case SET_PROJECTS:
      return { ...state, projects: action.payload };
    case SET_RESEARCHES:
      return { ...state, researches: action.payload };
    case SET_VARIABLES:
      return { ...state, variables: action.payload };
    case UPDATE_VARIABLES:
      return { ...state, variables: [...state.variables, action.payload] };
    case UPDATE_VARIABLE:
      return {
        ...state,
        variables: updateById(state.variables, action.payload)
      };
    case UPDATE_RESEARCH:
      return {
        ...state,
        researches: updateById(state.researches, action.payload)
      };
    case ADD_PROJECT_TAG:
      return { ...state, projects: addNewProjectTag(state, action.payload) };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { Welcome } from './Welcome';
import { InstallExtension } from './InstallExtension';
import { LogInExtension } from './LogInExtension';
import { CreateFirstProject } from './CreateFirstProject';

export function WelcomePage() {
  const welcome = 0,
    installExtension = 1,
    logInExtension = 2,
    createFirstProject = 3;
  
  const [current, setCurrent] = useState(welcome);

  function ComponentSwitch() {
    switch (current) {
      case welcome:
        return <Welcome setCurrent={setCurrent} />;
      case installExtension:
        return <InstallExtension setCurrent={setCurrent} />;
      case logInExtension:
        return <LogInExtension setCurrent={setCurrent} />;
      case createFirstProject:
        return <CreateFirstProject setCurrent={setCurrent} />;
      default:
        return null;
    }
  }

  return <ComponentSwitch />;
}

import { useCallback, useEffect, useRef, useState } from 'react';

export function useTimeout(callback, delay) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
}

export function useDebounce(callback, delay, dependencies) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}

export function usePagination(limit = 50, values = []) {
  const initialOffset = 0;
  const [offset, setOffset] = useState(initialOffset);
  const [chunk, setChunk] = useState(values);

  const prevPage = () => {
    setOffset((prev) => (prev <= limit ? initialOffset : prev - limit));
  };

  const nextPage = () => {
    setOffset((prev) => prev + limit);
  };

  useEffect(() => {
    setChunk(values.slice(offset, limit));
  }, [offset]);

  return {
    limit,
    offset,
    prevPage,
    nextPage,
    chunk
  };
}

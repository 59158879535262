import * as Yup from 'yup';
import { regex } from '../../constants';

export const formConfig = {
  signUp: {
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is a required field')
    }),
    validateOnBlur: true,
    validateOnChange: false
  },
  signUpForm: {
    initialValues: {
      name: '',
      company: '',
      role: '',
      password: '',
      repeat: '',
      subscribed: true,
      promoCode: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is a required field'),
      company: Yup.string(),
      role: Yup.string(),
      password: Yup.string()
        .required('Password is a required field')
        .min(8, 'Password must be at least 8 characters')
        .max(15, 'Password must be at most 15 characters')
        .matches(
          regex.password,
          'Password must contain Uppercase and lowercase letters, numbers, and special characters'
        ),
      repeat: Yup.string()
        .required('Password is a required field')
        .oneOf([Yup.ref('password'), null], "Passwords don't match"),
      promoCode: Yup.string().max(50, 'Promo code must be at most 50 characters'),
    }),
    validateOnBlur: false,
    validateOnChange: false
  }
};

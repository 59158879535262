import React, { useState } from 'react';
import classes from './popups.module.scss';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/svg/CloseIcon.svg';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import { dataTypes } from './module';
import { useDispatch, useSelector } from 'react-redux';
import { hasValues } from '../../utils/utils';
import { notify } from '../../components/Snackbar';
import { createProjectVariable, updateUserVariable } from '../../api/user';
import {
  updateVariableAction,
  updateVariablesAction
} from '../../store/reducers/user';
import { useParams } from 'react-router-dom';
import { BootstrapTooltip } from '../../components/BootstrapTooltip';
import { tooltips } from '../modals/module';
import { AnalyticsEvent } from '../../utils/analytics';

export function CreateVariable({ onClose, researchId = null }) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const user = useSelector(({ user }) => user);
  const researchSources = user.researches || [];

  const variables = user.variables || [];

  const updateVariable = researchId !== null;
  const title = updateVariable ? 'Edit value' : 'Create value';
  const action = updateVariable ? 'Save changes' : 'Create value';
  const { extract, dataType, value, name } =
    variables.find((v) => v.id === researchId) || {};
  const { id } = researchSources.find((r) => r.extract === extract) || {};

  const initialState = {
    extract: id || 'none',
    dataType: dataType || dataTypes[0].value,
    value: value || '',
    name: name || ''
  };

  const [form, setForm] = useState(initialState);

  function handleVariableChange(e) {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  }

  function getFormValues() {
    const item = researchSources.find((source) => source.id === form.extract);
    return { ...form, extract: item?.extract, source: item?.source };
  }

  function handleCreateVariable() {
    if (!hasValues(form)) return notify.warning('All fields are required!');
    createProjectVariable({
      projectId,
      ...getFormValues()
    }).then((data) => {
      dispatch(updateVariablesAction(data));
      setForm(initialState);
    });
  }

  function handleUpdateVariable() {
    if (!hasValues(form)) return notify.warning('All fields are required!');
    updateUserVariable(researchId, {
      ...getFormValues()
    }).then((variable) => {
      dispatch(updateVariableAction(variable));
      onClose();
    });
  }

  const decoratedCallback = updateVariable
    ? handleUpdateVariable
    : handleCreateVariable;

  const createVariableClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'create_variable_button_click'
    },
    decoratedCallback
  );

  return (
    <div className={classes.main}>
      <div className={`fontOutfit ${classes.header}`}>
        <div>{title}</div>
        <div className={classes.icon} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.source}>
          <div className={`fontOutfit ${classes.label}`}>
            Source Extract
            <BootstrapTooltip title={tooltips.sourceExtract}>
              <InfoIcon className={classes.info} />
            </BootstrapTooltip>
          </div>
          <div className={classes.content}>
            <Select
              className="w-100"
              name="extract"
              value={form.extract}
              onChange={handleVariableChange}
            >
              <MenuItem value="none">None</MenuItem>
              {researchSources.map(({ extract, id }) => (
                <MenuItem key={id} value={id}>
                  {extract?.slice(0, 90)}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className={classes.fields}>
          <div className={classes.variable}>
            <div className={`fontOutfit ${classes.label}`}>
              Value Name
              <BootstrapTooltip title={tooltips.variableName}>
                <InfoIcon className={classes.info} />
              </BootstrapTooltip>
            </div>
            <div className={classes.content}>
              <TextField
                className="w-100"
                placeholder="Enter value name"
                name="name"
                value={form.name}
                onChange={handleVariableChange}
              />
            </div>
          </div>
          <div className={classes.dataType}>
            <div className={`fontOutfit ${classes.label}`}>
              Data Type
              <BootstrapTooltip title={tooltips.dataType}>
                <InfoIcon className={classes.info} />
              </BootstrapTooltip>
            </div>
            <div className={classes.content}>
              <Select
                displayEmpty
                className="w-100"
                name="dataType"
                value={form.dataType}
                onChange={handleVariableChange}
              >
                {dataTypes.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={classes.value}>
            <div className={`fontOutfit ${classes.label}`}>
              Value
              <BootstrapTooltip title={tooltips.variableValue}>
                <InfoIcon className={classes.info} />
              </BootstrapTooltip>
            </div>
            <div className={classes.content}>
              <TextField
                name="value"
                className="w-100"
                placeholder="Enter value"
                value={form.value}
                onChange={handleVariableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Button variant="text" onClick={() => onClose()}>
          Back
        </Button>
        <Button className={classes.next} onClick={createVariableClick}>
          {action}
        </Button>
      </div>
    </div>
  );
}

export const appVersion = '5.0.0-28.04.2022';

export const snack = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};

const development = {
  lsTokenKey: 'auth-dev',
  isDevelopment: true,
  APIbaseURL: 'http://localhost:4000/api/'
};

const production = {
  lsTokenKey: 'auth-prod',
  isDevelopment: false,
  APIbaseURL: 'https://api.analystpro.io/api/'
};

export const env =
  process.env.NODE_ENV === 'production' ? production : development;

export const extensionId = 'gnbadckbnacgkondohadcpejpmefkcee';

export const userConfig = {
  lsConfigKey: 'analyst-pro',
  projects: {},
  researches: {},
  variables: {}
};

import React from 'react';
import { Select } from '@mui/material';

function Placeholder({ children }) {
  return (
    <div
      style={{
        color: 'currentColor',
        opacity: 0.42
      }}
    >
      {children}
    </div>
  );
}

export function CustomSelect({ placeholder, children, ...props }) {
  const hasValue = props.multiple ? props.value.length : props.value;
  const phProps = {
    displayEmpty: true,
    renderValue: !hasValue
      ? () => <Placeholder children={placeholder} />
      : undefined
  };
  const customProps = {
    ...props,
    ...(placeholder && phProps)
  };
  return <Select {...customProps}>{children}</Select>;
}

import React from 'react';

export const tabStyles = {
  tab: {
    padding: '10px 0',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '19px',
    letterSpacing: '0.02em',
    marginRight: '40px'
  },
  active: {},
  indicator: {}
};

export const inputStyles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    height: '32px'
  }
};

export const dataTypes = [
  {
    label: 'Number',
    value: 'number'
  },
  {
    label: 'Date',
    value: 'date'
  },
  {
    label: 'Percentage',
    value: 'percentage'
  },
  {
    label: 'Currency (£)',
    value: 'gbp'
  },
  {
    label: 'Currency ($)',
    value: 'usd'
  },
  {
    label: 'Currency (€)',
    value: 'eur'
  }
];

export const researchColumns = [
  {
    id: 'source',
    numeric: false,
    label: 'Source',
    active: true,
    filter: true
  },
  {
    id: 'images',
    numeric: false,
    label: 'Images',
    active: false
  },
  {
    id: 'extract',
    numeric: false,
    label: 'Extract',
    active: true,
    filter: true
  },
  {
    id: 'comment',
    numeric: false,
    label: 'Comment',
    active: true,
    filter: true
  },
  {
    id: 'tags',
    numeric: false,
    label: 'Tags',
    active: true,
    filter: true
  },
  {
    id: 'createdAt',
    numeric: true,
    label: 'Added',
    active: false,
    filter: true
  },
  {
    id: 'updatedAt',
    numeric: true,
    label: 'Last modified',
    active: false
  },
  {
    id: 'id',
    numeric: true,
    label: 'ID',
    active: false
  }
];

export const variableColumns = [
  {
    id: 'id',
    numeric: true,
    label: 'ID',
    active: false,
    filter: true
  },
  {
    id: 'name',
    numeric: false,
    label: 'Value name',
    active: true
  },
  {
    id: 'value',
    numeric: false,
    label: 'Value',
    active: true,
    filter: true
  },
  {
    id: 'dataType',
    numeric: false,
    label: 'Data type',
    active: true,
    filter: true
  },
  {
    id: 'source',
    numeric: false,
    label: 'Source',
    active: false,
    filter: true
  },
  {
    id: 'extract',
    numeric: false,
    label: 'Extract',
    active: false,
    filter: true
  },
  // {
  //   id: 'comment',
  //   numeric: true,
  //   label: 'Comment',
  //   active: false
  // },
  // {
  //   id: 'tags',
  //   numeric: true,
  //   label: 'Tags',
  //   active: false
  // },
  {
    id: 'createdAt',
    numeric: true,
    label: 'Added',
    active: false,
    filter: true
  },
  {
    id: 'updatedAt',
    numeric: true,
    label: 'Last modified',
    active: false
  }
];

export const tagColors = [
  { name: 'cherry', value: '#DE005B' },
  { name: 'white', value: '#fff' },
  { name: 'blue', value: '#0000ff' },
  { name: 'black', value: '#222a35' }
];

export const data = {
  export: [{ label: '.xlsx', active: true }]
};

export const projectMenuItems = ({
  onNameChange,
  onProjectDelete,
  onTagManager
}) => [
  {
    label: 'Change project name',
    onClick: onNameChange
  },
  {
    label: 'Manage tags',
    onClick: onTagManager
  },
  {
    label: 'Delete project',
    onClick: onProjectDelete
  }
];

export const tableMenuItems = ({ onDelete, onEdit }) => [
  {
    label: 'Edit',
    onClick: onEdit
  },
  {
    label: 'Delete',
    onClick: onDelete
  }
];

import React, { useState, useEffect } from 'react';
import classes from './login.module.scss';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link
} from '@mui/material';
import { ReactComponent as LogoIcon } from '../../assets/svg/Icon.svg';
import { ReactComponent as AnalystPro } from '../../assets/svg/AnalystPro.svg';
import { signUp } from '../../api/auth';
import { notify } from '../../components/Snackbar';
import { useFormik } from 'formik';
import { formConfig } from '../sign-up/module';
import { SignUpEmailPage } from './SignUpEmailPage';
import { useNavigate } from 'react-router-dom';
import { setIdentity } from '../../utils/hubspotAnalytics';

export function SignUpPage({ invitation = undefined }) {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Sign up | AnalystPro';
  }, []);

  const [loading, setLoading] = useState(!!invitation);
  const [form, setForm] = useState({
    name: '',
    company: '',
    role: '',
    secret: '',
    accountType: 'custom',
    invitation: invitation,
    promoCode: '',
    email: '',
    isSubscribed: false
  });
  const [newPassword, setNewPassword] = useState(false);
  const [screan, setScrean] = useState(1);

  function handleSubmitFirst() {
    setScrean(2);
  }

  function handleSubmitSecond() {
    formik.handleSubmit();
  }

  const { values, errors, handleChange, validateField, ...formik } = useFormik({
    ...formConfig.signUpForm,
    onSubmit: (values) => {
      setLoading(true);
      signUp({
        name: values.name,
        company: values.company,
        role: values.role,
        secret: values.password,
        accountType: 'custom',
        isSubscribed: values.subscribed,
        invitation,
        email: form.email,
        promoCode: values.promoCode
      })
        .then(() => {
          notify.success('Account has been created!');
          setScrean(3);
          setIdentity(form.email, {});
        })
        .finally(() => setLoading(false));
    }
  });

  const handleKeyPressSignUp = (e) => {
    if (e.key === 'Enter') formik.handleSubmit();
  };

  const toggleNew = () => {
    setNewPassword(!newPassword);
  };

  function goSignin() {
    navigate('/signin');
  }

  return (
    <div>
      <header className={classes.appbar}>
        <div className={classes.logo}>
          <LogoIcon />
          <AnalystPro />
        </div>
      </header>
      {screan === 1 ? (
        <SignUpEmailPage
          handleSubmitFirst={handleSubmitFirst}
          setForm={setForm}
        />
      ) : screan === 2 ? (
        <div className={classes.center}>
          <div className={classes.main} onKeyPress={handleKeyPressSignUp}>
            <div className="container">
              <h2 className="fontOutfit title text-center mb-1">
                Finish signing up
              </h2>
              <p className="fontOutfit text mb-1 mt-1">
                Continue to create your account. Personalise your experience by
                telling us a bit about you.
              </p>
              <TextField
                name="email"
                className="w-100 mb-1 italic"
                placeholder="Email"
                value={form.email}
                disabled
              />
              <TextField
                className="w-100 mb-4"
                placeholder="Name"
                name="name"
                value={values.name}
                autoComplete="new-password"
                onChange={async (e) => {
                  await handleChange(e);
                  await validateField('name');
                }}
                helperText={errors.name}
                error={!!errors.name}
              />
              <TextField
                className="w-100 mb-4"
                placeholder="Company"
                name="company"
                autoComplete="new-password"
                value={values.company}
                onChange={(e) => {
                  handleChange(e);
                  validateField('company');
                }}
                helperText={errors.company}
                error={!!errors.company}
              />
              <TextField
                className="w-100 mb-1"
                placeholder="Role"
                name="role"
                autoComplete="new-password"
                value={values.role}
                onChange={(e) => {
                  handleChange(e);
                  validateField('role');
                }}
                helperText={errors.role}
                error={!!errors.role}
              />
              <TextField
                className="w-100 mb-4"
                placeholder="Password"
                name="password"
                type={newPassword ? 'text' : 'password'}
                value={values.password}
                onChange={async (e) => {
                  await handleChange(e);
                  await validateField('password');
                }}
                helperText={errors.password}
                error={!!errors.password}
              />
              <TextField
                className="w-100"
                placeholder="Repeat Password"
                type={newPassword ? 'text' : 'password'}
                name="repeat"
                value={values.repeat}
                onChange={async (e) => {
                  await handleChange(e);
                  await validateField('repeat');
                }}
                helperText={errors.repeat}
                error={!!errors.repeat}
              />
              <FormControlLabel
                className="mb-1"
                checked={newPassword}
                onChange={toggleNew}
                control={
                  <Checkbox
                    size="small"
                    style={{
                      margin: '2px 8px 2px 12px',
                      padding: 0
                    }}
                  />
                }
                label="Show password"
                labelPlacement="end"
                value="end"
              />

              <TextField
                className="w-100 mb-1"
                placeholder="Promo Code"
                name="promoCode"
                value={values.promoCode}
                onChange={(e) => {
                  handleChange(e);
                  validateField('promoCode');
                }}
                helperText={errors.promoCode}
                error={!!errors.promoCode}
              />

              <div className="mb-1 d-flex align-start">
                <Checkbox
                  disableRipple
                  size="small"
                  type="checkbox"
                  name="subscribed"
                  checked={values.subscribed}
                  onChange={handleChange}
                  style={{ marginTop: 2, marginRight: 8, padding: 0 }}
                />
                <div className="fontOutfit text-xs">
                  I’d like to receive the occasional product update, digestible
                  newsletter or cool research tricks.
                </div>
              </div>

              <div className="fontOutfit mb-1 text-xs">
                By selecting <span className="bold">Agree and continue</span>{' '}
                below, I agree to AnalystPro’s{' '}
                <Link
                  href="https://drive.google.com/file/d/1iRTbX_KcSgTvAu0Bw3JN_V3ylLnApLm-/view?usp=sharing"
                  target="_blank"
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  href="https://drive.google.com/file/d/10v5lki1vxvnaYoYELzf_558ujB839PE4/view?usp=sharing"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                .
              </div>
              <Button
                className="w-100"
                onClick={(e) => {
                  handleSubmitSecond();
                }}
                disabled={loading}
              >
                Agree and continue
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.center}>
          <div className={classes.main} onKeyPress={handleKeyPressSignUp}>
            <div className="container">
              <h2 className="fontOutfit title text-center mb-1">
                Verify email
              </h2>
              <div className="fontOutfit text mb-1">
                Thank you for signing up! <br />
                <br />
                Please verify you email address by clicking <br />
                the email verification link in your inbox <br />
                within the next 24 hours (check your junk).
                <br />
                <br />
                We have sent you the email verification link.
                <br />
                It may take up to 5 minutes to arrive.
              </div>
              <Button className="w-100 mb-1" onClick={goSignin}>
                I’ve verified my email
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

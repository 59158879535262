import * as Yup from 'yup';

export const formConfig = {
  inviteForm: {
    initialValues: [{ email: '' }],

    validationSchema: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is a required field')
      })
    ),

    validateOnBlur: true,
    validateOnChange: false
  }
};

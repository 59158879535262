import React, { useEffect, useState } from 'react';
import classes from './research-dashboard.module.scss';
import IconButton from '@mui/material/IconButton';
import { Drawer } from '@mui/material';
import { TabProvider } from '../../components/TabProvider';
import { ReactComponent as ThreeDots } from '../../assets/svg/ThreeDotsIcon.svg';
import { ResearchBoard } from './ResearchBoard';
import { QuantAnalysis } from './QuantAnalysis';
import { projectMenuItems, tabStyles } from './module';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownMenu } from '../../components/DropdownMenu';
import { ModalComponents, useModalComponents } from '../modals/ModalComponents';
import {
  deleteProject,
  getProjectResearches,
  getProjectVariables
} from '../../api/user';
import {
  setProjectsAction,
  setResearchesAction,
  setVariablesAction
} from '../../store/reducers/user';
import TagManager from './TagManager';

export function ResearchDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [params] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const initialTab = params.get('tab') === 'variables' ? 1 : 0;
  const [tab, setTab] = useState(initialTab);
  const user = useSelector(({ user }) => user);
  const { projects = [] } = user;
  const [tagManager, setTagManager] = useState(false);
  const project = projects.find((project) => project.id == projectId);

  useEffect(() => {
    document.title = "Research | AnalystPro";
  }, []);

  const onTabChange = (e, newValue) => {
    setTab(newValue);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { modal, modals, modalOpen, handleModalClose, openModal } =
    useModalComponents();

  const projectOptions = projectMenuItems({
    onNameChange: () =>
      openModal(modals.changeProjectName, { onSubmit: handleModalClose }),
    onTagManager: () => {
      setTagManager(true);
    },
    onProjectDelete: () =>
      openModal(modals.confirmAction, {
        onCancel: handleModalClose,
        title: 'Are you sure you want to delete this project?',
        description: `${project.name} will be lost forever! (A long time!)`,
        onConfirm: () => {
          deleteProject(projectId).then((id) => {
            dispatch(setProjectsAction(projects.filter((p) => p.id !== id)));
            handleModalClose();
            navigate('/home');
          });
        }
      })
  });

  const closeTagManager = () => {
    setTagManager(false);
  };

  const tabs = [
    {
      label: 'Research list',
      component: <ResearchBoard />
    },
    {
      label: 'Values',
      component: <QuantAnalysis />
    }
  ];

  useEffect(() => {
    getProjectResearches({ projectId }).then(({ data }) =>
      dispatch(setResearchesAction(data))
    );
    getProjectVariables({ projectId }).then(({ data }) =>
      dispatch(setVariablesAction(data))
    );
  }, [projectId]);

  return (
    <div className={classes.main}>
      <div className={`fontOutfit ${classes.header}`}>
        <h4 className="fontOutfit m-0">{project?.name}</h4>
        <IconButton onClick={handleMenuOpen}>
          <ThreeDots />
        </IconButton>
        <DropdownMenu
          open={menuOpen}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          items={projectOptions}
        />
      </div>
      <div>
        <TabProvider
          tabs={tabs}
          sx={tabStyles}
          value={tab}
          onChange={onTabChange}
        />
      </div>
      <ModalComponents
        modal={modal}
        open={modalOpen}
        onClose={handleModalClose}
      />
      <Drawer anchor="right" open={tagManager}>
        <TagManager onClose={closeTagManager} />
      </Drawer>
    </div>
  );
}

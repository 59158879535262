import * as Yup from 'yup';
import { regex } from '../../constants';

export const resetPasswordForm = {
  initialValues: {
    password: '',
    repeat: ''
  },
  validationSchema: Yup.object({
    password: Yup.string()
      .required('Password is a required field')
      .min(8, 'Password must be at least 8 characters')
      .max(15, 'Password must be at most 15 characters')
      .matches(
        regex.password,
        'Password must contain Uppercase and lowercase letters, numbers, and special characters'
      ),
    repeat: Yup.string()
      .required('Password is a required field')
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
  }),
  validateOnBlur: false,
  validateOnChange: false
};

export const tooltips = {
  sourceExtract:
    'Select the Source Extract. Choose a saved Extract from your Research List or select None if you would like to create a value that is not linked to a source.',
  variableName:
    'Choose a Value Name. This will help you identify your values.',
  dataType:
    'Select the Data Type. If your required Data Type is not available, please request it by sending an email to joe@analystpro.io.',
  variableValue:
    'Enter the Value. Clarify the Value of the value you would like to save. This will be automated soon.'
};

import React, { useState } from 'react';
import { LogIn } from '../log-in/LogIn';
import { SignUp } from '../sign-up/SignUp';
import { SignUpForm } from '../sign-up/SignUpForm';
import { VerifySignUp } from './VerifySignUp';
import { ResetPassword } from '../reset-password/ResetPassword';
import { SuccessResetPassword } from '../reset-password/SuccessResetPassword';
import { AccountVerification } from './AccountVerification';
import { ChangeColumns } from '../research-dashboard/ChangeColumns';
import { CreateVariable } from '../research-dashboard/CreateVariable';
import { TableFilter } from '../research-dashboard/TableFilter';
import { ExportModal } from '../research-dashboard/Export';
import { ExportModalConfirm } from '../research-dashboard/ExportConfirm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { ModalProvider } from '../../components/Modal';
import { getObjectKey } from '../../utils/utils';
import { ChangeProjectName } from './ChangeProjectName';
import { ConfirmTagDelete } from '../research-dashboard/ConfirmTagDelete';
import { ConfirmAction } from './ConfirmAction';
import { AddNotes } from './AddNotes';
import { UpdateTag } from '../research-dashboard/UpdateTag';
import { CreateTag } from './CreateTag';
import { SidebarHelp } from './SidebarHelp';

export const modals = {
  logIn: {
    component: LogIn
  },
  signUp: {
    component: SignUp
  },
  signUpForm: {
    component: SignUpForm
  },
  verifySignUp: {
    component: VerifySignUp
  },
  resetPassword: {
    component: ResetPassword
  },
  successResetPassword: {
    component: SuccessResetPassword
  },
  accountVerification: {
    component: AccountVerification
  },
  changeDataColumns: {
    component: ChangeColumns,
    sx: { padding: '0px', maxWidth: 352 }
  },
  createVariable: {
    component: CreateVariable,
    sx: {
      padding: '0px',
      maxWidth: '820px',
      width: '100%'
    }
  },
  filterData: {
    component: TableFilter,
    sx: { padding: '0px', maxWidth: 352 }
  },
  exportData: {
    component: ExportModal,
    sx: { padding: '0px', maxWidth: 352 }
  },
  exportDataConfirm: {
    component: ExportModalConfirm,
    sx: { padding: '0px', maxWidth: 640, maxHeight: 198 }
  },
  resetPasswordForm: {
    component: ResetPasswordForm
  },
  changeProjectName: {
    component: ChangeProjectName
  },
  updateTag: {
    component: UpdateTag,
    sx: { padding: '0px', maxWidth: 352 }
  },
  confirmTagDelete: {
    component: ConfirmTagDelete,
    sx: { padding: '0px', maxWidth: 352 }
  },
  confirmAction: {
    component: ConfirmAction
  },
  addNotes: {
    component: AddNotes,
    sx: { padding: '0px', maxWidth: 424 }
  },
  createTag: {
    component: CreateTag,
    sx: { padding: '0px', maxWidth: 424 }
  },
  sidebarHelp: {
    component: SidebarHelp,
    sx: { padding: '48px 160px', maxWidth: 640 }
  }
};

export function ModalComponents({ open, modal, modalProps, onClose }) {
  if (!modal) return null;
  let modalComponent = {};
  const { current, props } = modal;
  const modalKey = getObjectKey(modals, current);
  if (!modalKey) return console.error('Modal does not exist!');

  if (modalProps) {
    const { defaultProps } = modalProps;
    modalComponent = { ...defaultProps, ...modalProps[modalKey] };
  }

  modalComponent = {
    ...modalComponent,
    ...modals[modalKey],
    ...props
  };

  const { sx, component: ModalComponent, ...rest } = modalComponent;

  return (
    <ModalProvider open={open} onClose={onClose} sx={sx}>
      <ModalComponent {...rest} />
    </ModalProvider>
  );
}

export function useModalComponents() {
  const [modal, setModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true),
    handleModalClose = () => setModalOpen(false);

  const openModal = (modal, props = {}) => {
    setModal({ current: modal, props });
    !modalOpen && handleModalOpen();
  };
  return {
    modal,
    modals,
    setModal,
    modalOpen,
    setModalOpen,
    handleModalOpen,
    handleModalClose,
    openModal
  };
}

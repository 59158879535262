import React from 'react';
import { Button } from '@mui/material';

export function VerifySignUp({ onSubmit }) {
  return (
    <div className="main">
      <div className="container">
        <h2 className="fontOutfit title text-center mb-1">Verify email</h2>
        <div className="fontOutfit text mb-1">
          Thank you for signing up! <br />
          <br />
          Please verify you email address by clicking <br />
          the email verification link in your inbox <br />
          within the next 24 hours (check your junk).
          <br />
          <br />
          We have sent you the email verification link.
          <br />
          It may take up to 5 minutes to arrive.
        </div>
        <Button className="w-100 mb-1" onClick={onSubmit}>
          Okay
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import { Button } from '@mui/material';

export function ConfirmAction({ title, description, onCancel, onConfirm }) {
  return (
    <div className="main">
      <div className="container">
        <h2 className="fontOutfit title text-center mb-1">{title}</h2>
        <h2 className="fontOutfit sub-title text-center mb-1">{description}</h2>
        <Button type="submit" className="w-100 mb-1" onClick={onConfirm}>
          Confirm
        </Button>
        <Button variant="outlined" className="w-100 " onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

import React from 'react';
import { isWhite } from '../utils/utils';

export function Source({ children, link = children }) {
  if (!children) return '-';
  return (
    <a className="research-source" href={link} target="_blank">
      {children}
    </a>
  );
}

export function ResearchTag({ tag, className }) {
  const color = isWhite(tag.color) ? '#000' : '#fff';
  const tagClasses = `tag ${className ? className : ''}`;
  const tagStyles = { backgroundColor: tag.color, color };
  return (
    <div className={tagClasses} style={tagStyles}>
      {tag.name}
    </div>
  );
}

export function MapResearchTags({ children = [] }) {
  if (!children.length) return '-';
  return (
    <div className="d-flex wrap" style={{ margin: -8 }}>
      {children.map((tag, key) => (
        <ResearchTag key={key} tag={tag} className="m-8" />
      ))}
    </div>
  );
}

export function MapImages({ children }) {
  if (Array.isArray(children) && children.length) {
    return children.map((img, key) => (
      <Source key={key} link={img.url}>
        {img.url}
      </Source>
    ));
  }
  return '-';
}

export function Mark({ text = '', keyword = '', images = [], type = false }) {
  if ((!text || text === ' - ') && images?.length > 0) return '(Image)';
  if ((!text || text === ' - ') && images?.length < 1 && type)
    return '(Entire page)';
  if (!text) return '-';
  if (!keyword) return text;
  const lowerCaseText = text.toLowerCase(),
    lowerCaseKeyword = keyword.toLowerCase();
  if (!lowerCaseText.includes(lowerCaseKeyword)) return text;

  const marked = [],
    occurrences = lowerCaseText.split(lowerCaseKeyword);
  if (occurrences.includes(lowerCaseKeyword)) {
    return text;
  }
  const results = [];
  let textStart = 0;
  let keyStart = 0;
  const keyLength = keyword.length;
  occurrences.forEach((txt) => {
    keyStart += txt.length;
    const keyEnd = keyStart + keyLength;
    results.push({
      text: text.slice(textStart, keyStart),
      keyword: text.slice(keyStart, keyEnd)
    });
    textStart = keyEnd;
    keyStart += keyLength;
  });

  for (let i = 0; i < results.length; i++) {
    if (!results[i + 1] && i > 0) {
      marked.push(results[i].text);
    } else {
      marked.push(
        <>
          {results[i].text && results[i].text}
          <span className="mark-yellow">{results[i].keyword}</span>
        </>
      );
    }
  }
  return marked.map((item, i) => {
    return <span key={i}>{item}</span>;
  });
}

import React, { useEffect, useState } from 'react';
import classes from './research-dashboard.module.scss';
import { Button, InputAdornment, TextField } from '@mui/material';
import {
  variableColumns as defaultColumns,
  inputStyles,
  tableMenuItems
} from './module';
import { EnhancedTable } from '../../components/EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../assets/svg/Lense.svg';
import { ReactComponent as Filter } from '../../assets/svg/FilterIcon.svg';
import { ReactComponent as Export } from '../../assets/svg/Export.svg';
import { ModalComponents, useModalComponents } from '../modals/ModalComponents';
import { notify } from '../../components/Snackbar';
import {
  createProjectVariable,
  deleteVariable,
  getProjectVariables,
  getUserExcelVariables,
  updateUserVariable
} from '../../api/user';
import {
  containsValue,
  hasValues,
  lsGetUserConfig,
  lsUpdateUserConfig,
  mapEmpty,
  mapFilterValues
} from '../../utils/utils';
import { setVariablesConfig } from '../../store/reducers/config';
import {
  setVariablesAction,
  updateVariablesAction
} from '../../store/reducers/user';
import { useDebounce } from '../../hooks/hooks';
import { NoVariables } from './NoVariables';
import { AnalyticsEvent } from '../../utils/analytics';

export function QuantAnalysis() {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [columns, setColumns] = useState(defaultColumns);

  const user = useSelector(({ user }) => user);
  const projectVariables = user.variables;
  const currentProject = user.projects.find((p) => p.id == projectId);
  const tags = currentProject?.tags || [];

  const [variables, setVariables] = useState(projectVariables);

  const projectSchema = useSelector(({ config }) => config.variables || false);
  const currentSchema = projectSchema[projectId];
  const columnsChanged = columns !== defaultColumns;

  const filters = columns.filter((c) => c.filter);
  const [filterActive, setFilterActive] = useState(false);

  const { modal, modalOpen, modals, openModal, handleModalClose } =
    useModalComponents();

  useEffect(() => {
    setVariables(projectVariables);
  }, [projectVariables]);

  useEffect(() => {
    setFilterActive(false);
    if (!projectSchema) {
      const userConfig = lsGetUserConfig();
      if (!userConfig) return;
      if (userConfig.variables) {
        dispatch(setVariablesConfig(userConfig.variables));
      }
    }
    if (!currentSchema) return setColumns(defaultColumns);
    setColumns(currentSchema);
  }, [projectId]);

  useEffect(() => {
    if (currentSchema) {
      setColumns(currentSchema);
    }
  }, [currentSchema]);

  useEffect(() => {
    if (columnsChanged) {
      const newColumns = {
        ...projectSchema,
        [projectId]: columns
      };
      dispatch(setVariablesConfig(newColumns));
      lsUpdateUserConfig({ variables: newColumns });
    }
  }, [columns]);

  const createVariableClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'open_create_variable_button_click'
    },
    () => openModal(modals.createVariable)
  );

  const handleRowDelete = (variableId) => {
    deleteVariable(variableId)
      .then(() => {
        const variables = projectVariables.filter((v) => v.id !== variableId);
        dispatch(setVariablesAction(variables));
      })
      .catch((e) => notify.error('Failed to delete!', e));
  };

  const handleResearchUpdate = (
    researchId,
    { form, variable, updateForm, createVariable }
  ) => {
    if (updateForm) {
      if (!containsValue(form))
        return notify.warning('Provide comment or tag to edit!');
      updateUserVariable(researchId, mapEmpty(form)).then((variable) => {
        const vid = projectVariables.findIndex((v) => v.id === variable.id);
        projectVariables[vid] = { ...projectVariables[vid], ...variable };
        dispatch(setVariablesAction([...projectVariables]));
      });
    }

    if (createVariable) {
      if (hasValues(variable)) {
        createProjectVariable({
          ...variable,
          projectId
        }).then((data) => {
          dispatch(updateVariablesAction(data));
        });
      }
    }

    handleModalClose();
  };

  const handleRowEdit = (researchId) => {
    openModal(modals.createVariable, { researchId });
  };

  const menuItems = tableMenuItems({
    onDelete: handleRowDelete,
    onEdit: handleRowEdit
  });

  const [search, setSearch] = useState('');
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleColumnChange = (columns) => {
    setColumns(columns);
    handleModalClose();
  };

  const handleFilterApply = (id, phrase) => {
    let filterValue = phrase.trim();
    if (!filterValue.length) return notify.warning('Filter has no value!');
    if (filterValue.length > 128)
      return notify.warning('Filter values is too long!');
    filterValue = mapFilterValues(id, filterValue);
    getProjectVariables({
      projectId,
      [id]: filterValue
    }).then(({ data }) => {
      if (!data.length) return notify.info('No values match the filter!');
      notify.success('Filter has been applied!');
      setVariables(data);
      setFilterActive(true);
    });
    handleModalClose();
  };

  const handleFilterCancel = () => {
    setVariables(projectVariables);
    setFilterActive(false);
    handleModalClose();
  };

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    keyword && setKeyword('');
  }, [search]);

  const searchByTerm = () => {
    const searchValue = search.trim();
    if (searchValue.length > 164)
      return notify.warning('Search value is too long!');
    if (!searchValue.length) {
      return setVariables(projectVariables);
    }
    getProjectVariables({
      projectId,
      search: searchValue
    }).then(({ data }) => {
      if (!data.length) return notify.info('No items match the term');
      setVariables(data);
      setKeyword(search);
    });
  };

  useDebounce(searchByTerm, 1000, [search]);

  const modalProps = {
    defaultProps: {
      onSubmit: handleModalClose,
      onClose: handleModalClose,
      onCancel: handleModalClose
    },
    changeDataColumns: {
      columns: columns,
      onCancel: handleModalClose,
      onConfirm: handleColumnChange
    },
    filterData: {
      filters: filters,
      onCancel: handleFilterCancel,
      onConfirm: handleFilterApply
    },
    exportData: {
      onCancel: handleModalClose,
      onConfirm: () => {
        getUserExcelVariables(projectId);
        openModal(modals.exportDataConfirm);
      }
    }
  };

  return (
    <div className={classes.researchBoard}>
      {projectVariables.length ? (
        <div>
          <div className={classes.toolbar}>
            <TextField
              value={search}
              onChange={handleSearchChange}
              sx={inputStyles}
              placeholder="Search"
              className={classes.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Button
              disableElevation={true}
              className={filterActive ? classes.filterActive : classes.filter}
              onClick={() => openModal(modals.filterData)}
            >
              <Filter />
              Filter
            </Button>

            <Button
              disableElevation={true}
              className={classes.export}
              onClick={() => openModal(modals.exportData)}
            >
              <Export />
              Export
            </Button>

            <Button
              disableElevation={true}
              className={classes.create}
              onClick={createVariableClick}
            >
              Create value
            </Button>
          </div>

          <EnhancedTable
            keyword={keyword}
            columns={columns}
            rows={variables}
            menuItems={menuItems}
            onConfig={() => openModal(modals.changeDataColumns)}
          />
        </div>
      ) : (
        <NoVariables onClick={() => openModal(modals.createVariable)} />
      )}
      <ModalComponents
        modal={modal}
        open={modalOpen}
        onClose={handleModalClose}
        modalProps={modalProps}
      />
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import classes from './login.module.scss';
import {
  Button,
  Link,
  TextField,
  Stack,
  AlertTitle,
  Alert,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { logIn, resendVerificationLink } from '../../api/auth';
import { notify } from '../../components/Snackbar';
import { useDispatch } from 'react-redux';
import { logInAction, setProjectsAction } from '../../store/reducers/user';
import { hasValues } from '../../utils/utils';
import { getUserProjects } from '../../api/user';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/svg/Icon.svg';
import { ReactComponent as AnalystPro } from '../../assets/svg/AnalystPro.svg';
import { identifyUserForAnalytics } from '../../utils/analytics';
import { setIdentity } from '../../utils/hubspotAnalytics';
import { guestAxios } from '../../utils/axios';
import EmailVerified from './EmailVerified';
import { ModalProvider } from '../../components/Modal';

export function LogInPage() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.title = "Log in | AnalystPro";
  }, []);

  const [form, setForm] = useState({ email: '', secret: '' });
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const verification = params.get('verificationToken');
  useEffect(() => {
    const token = verification;
    verification &&
      guestAxios.post('/verification', { token }).then(() => {
        setOpen(true);
        notify.success('Account has been verified!');
      });
  }, [verification]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  function handleSubmit() {
    if (!hasValues({ ...form }))
      return notify.warning('All fields are required!');
    setLoading(true);
    logIn(form)
      .then((user) => {
        notify.success('Authorized');
        setIdentity(form.email, {});
        identifyUserForAnalytics(user);
        dispatch(logInAction(user, dispatch));
        return getUserProjects();
      })
      .then(({ data }) => {
        dispatch(setProjectsAction(data));
        data.length ? navigate('/home') : navigate('/getting-started');
      })
      .catch((e) => setAlertMessage(e.response?.status))
      .finally(() => setLoading(false));
  }

  function onReset() {
    navigate('/reset-password');
  }

  function goSignup() {
    navigate('/signup');
  }

  function InvalidCredentials() {
    return (
      <Alert severity="error" className="fontOutfit w-100 mb-1">
        <AlertTitle className="fontOutfit">Authentication failed</AlertTitle>
        The email address or password you have entered is incorrect
      </Alert>
    );
  }

  function CheckMailbox() {
    return (
      <Alert severity="success" className="fontOutfit w-100 mb-1">
        <AlertTitle className="fontOutfit">Email has been sent</AlertTitle>
        Please verify your email address by clicking the link sent to you before
        logging in for the first time
      </Alert>
    );
  }

  function ResendInvitation() {
    const handleResendEmail = () => {
      resendVerificationLink({
        email: form.email
      }).then(() => {
        notify.success('Success!');
        setAlertMessage(200);
      });
    };

    return (
      <Alert severity="error" className="fontOutfit w-100 mb-1">
        <AlertTitle className="fontOutfit">Authentication failed</AlertTitle>
        Please verify your email address before logging in for the first time.
        <br />
        <strong
          className="fontOutfit underline pointer"
          onClick={handleResendEmail}
        >
          Click here
        </strong>{' '}
        to resend verification link.
      </Alert>
    );
  }

  return (
    <div>
      <header className={classes.appbar}>
        <div className={classes.logo}>
          <LogoIcon />
          <AnalystPro />
        </div>
      </header>
      <div className={classes.center}>
        <div className={classes.main}>
          <div className="container w-100">
            <h2 className="fontOutfit title text-center mb-1">Log in</h2>
            <h4 className="fontOutfit text m-0 mb-8">Email address</h4>
            <TextField
              name="email"
              type="email"
              className="w-100 mb-1"
              placeholder="Enter email address"
              value={form.email}
              onChange={handleChange}
            />
            <h4 className="fontOutfit text m-0 mb-8">Password</h4>
            <TextField
              name="secret"
              type={passwordShown ? 'text' : 'password'}
              className="w-100"
              placeholder="Enter Password"
              value={form.secret}
              onChange={handleChange}
            />
            <FormControlLabel
              checked={passwordShown}
              onChange={togglePassword}
              control={<Checkbox />}
              label="Show password"
              labelPlacement="end"
              value="end"
            />

            <Stack direction="row" justifyContent="end">
              <Link
                component="button"
                name="Forgot password"
                className="link-sm d-block mt-8 mb-1"
                onClick={onReset}
              >
                Forgot password
              </Link>
            </Stack>
            {alertMessage === 401 ? (
              <InvalidCredentials />
            ) : alertMessage === 403 ? (
              <ResendInvitation />
            ) : alertMessage === 200 ? (
              <CheckMailbox />
            ) : null}
            <Button
              type="submit"
              className="w-100 mb-1"
              onClick={handleSubmit}
              disabled={loading}
            >
              Continue
            </Button>

            <Button
              variant="text"
              className="w-100 mb-1 goSignup"
              onClick={goSignup}
            >
              I don't have an account
            </Button>
          </div>
        </div>
      </div>
      <ModalProvider open={open} onClose={handleClose}>
        <EmailVerified handleClose={handleClose} />
      </ModalProvider>
    </div>
  );
}

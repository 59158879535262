import React, { useEffect, useState } from 'react';
import classes from './settings.module.scss';
import { Button, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { changeUserPassword } from '../../api/user';
import { useFormik } from 'formik';
import { securityForm } from './module';
import { AnalyticsEvent } from '../../utils/analytics';

export function LoginAndSecurity() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    values,
    errors,
    setValues,
    handleChange,
    validateField,
    handleSubmit
  } = useFormik({
    ...securityForm,
    onSubmit: AnalyticsEvent(
      {
        category: 'user_click',
        action: 'change_password_button_click'
      },
      (passwords) => {
        changeUserPassword({
          current: passwords.current,
          new: passwords.new
        })
          .then(() => {
            setValues(securityForm.initialValues);
          })
          .finally(() => setLoading(false));
      }
    )
  });

  useEffect(() => {
    if (values.repeat) {
      validateField('repeat');
    }
  }, [values.new]);

  async function handleFieldChange(e) {
    await handleChange(e);
    await validateField(e.target.name);
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.paper}>
      <h2 className="fontOutfit title">Login & Security</h2>

      <h4 className="fontOutfit">Old password</h4>
      <TextField
        name="current"
        className="w-100"
        placeholder="Enter old password"
        type={showPassword ? 'text' : 'password'}
        value={values.current}
        onChange={handleFieldChange}
        errors={!!errors.current}
        helperText={errors.current}
      />

      <h4 className="fontOutfit">New password</h4>
      <TextField
        name="new"
        className="w-100"
        placeholder="Enter new password"
        type={showPassword ? 'text' : 'password'}
        value={values.new}
        onChange={handleFieldChange}
        error={!!errors.new}
        helperText={errors.new}
      />

      <h4 className="fontOutfit">New password repeat</h4>
      <TextField
        name="repeat"
        className="w-100"
        placeholder="Repeat new password"
        type={showPassword ? 'text' : 'password'}
        value={values.repeat}
        onChange={handleFieldChange}
        error={!!errors.repeat}
        helperText={errors.repeat}
      />
      <FormControlLabel
        checked={showPassword}
        onChange={toggleShowPassword}
        control={<Checkbox />}
        label="Show password"
        labelPlacement="end"
        value="end"
      />

      <Button
        variant="outlined"
        onClick={handleSubmit}
        disabled={loading}
        className={classes.buttonSm}
      >
        Save
      </Button>
    </div>
  );
}

import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { userReducer } from './reducers/user';
import { appConfigReducer } from './reducers/config';

const rootReducer = combineReducers({
  user: userReducer,
  config: appConfigReducer
});

export const store = createStore(rootReducer, composeWithDevTools());

import React from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import classes from './first-project.module.scss';
import { setFirstLogIn } from '../../store/reducers/user';

export function FirstProjectNoVariables() {
  const dispatch = useDispatch();
  const updateFirstLogIN = () => {
    dispatch(setFirstLogIn(false));
  };

  const handleGoYoutube = () => {
    window.open('https://vimeo.com/733680567');
  };

  return (
    <div className={classes.noVariables}>
      <div className={classes.mainTitle}>What are values?</div>
      <div className={`${classes.textExtracts} ${classes.mb16}`}>
        Values are quantitative data. Creating a value from the original source
        will make it easier to save, organise and trace these data. You can
        create a value by:
      </div>

      <ul>
        <li className={`${classes.textExtracts} ${classes.mb16}`}>
          <b>Browser extension</b> - Directly through your browser extension by
          using Add Notes and then clicking Create Value.
        </li>
      </ul>

      <ul>
        <li className={`${classes.textExtracts} ${classes.mb16}`}>
          <b>Web app</b> -Through the web app by clicking Create Value and
          referencing the specific extract that is saved in your project.
        </li>
      </ul>

      <div className={`${classes.textExtracts} ${classes.mb36}`}>
        <b>AnalystPro tip:</b> export the values directly to use them in your
        Excel model to retain a traceable source of truth.
      </div>

      <Button type="submit" className="w-100 mb-36" onClick={handleGoYoutube}>
        Show me how
      </Button>
      <Button variant="outlined" className="w-100" onClick={updateFirstLogIN}>
        I’ll figure it out
      </Button>
    </div>
  );
}

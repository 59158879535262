import { userAxios } from '../utils/axios';
import { composeQuery } from '../utils/utils';

export async function getUserInfo() {
  const response = await userAxios.get('/users/me');
  if (response?.data) {
    return response.data;
  }
}

export async function updateUserInfo(data) {
  const response = await userAxios.patch('/users/me', data);
  if (response?.data) {
    return response.data;
  }
}

export async function downloadDocument(data, value = '') {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', value);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export async function getUserExcelResearches(projectId) {
  await userAxios
    .get('/excel/researches?projectId=' + projectId, {
      responseType: 'blob'
    })
    .then(({ data }) => downloadDocument(data, 'Researches'))
    .catch(console.error);
}

export async function getUserExcelVariables(projectId) {
  await userAxios
    .get('/excel/variables?projectId=' + projectId, {
      responseType: 'blob'
    })
    .then(({ data }) => downloadDocument(data, 'Variables'))
    .catch(console.error);
}

export async function createResearch(data) {
  const response = await userAxios.post('/researches', data);
  if (response?.data) {
    return response.data;
  }
}

export async function getUserProjects() {
  const response = await userAxios.get('/projects');
  if (response?.data) {
    return response.data;
  }
}

export async function createProject(data) {
  const response = await userAxios.post('/projects', data);
  if (response?.data) {
    return response.data;
  }
}

export async function changeUserPassword(data) {
  const response = await userAxios.post('/change-password', data);
  if (response?.data) {
    return response.data;
  }
}

export async function getProjectResearches(data) {
  const queryString = composeQuery(data);
  const response = await userAxios.get('/researches' + queryString);
  if (response?.data) {
    return response.data;
  }
}

export async function getProjectVariables(data) {
  const queryString = composeQuery(data);
  const response = await userAxios.get('/variables' + queryString);
  if (response?.data) {
    return response.data;
  }
}

export async function createProjectVariable(data) {
  const response = await userAxios.post('/variables', data);
  if (response?.data) {
    return response.data;
  }
}

export async function inviteFriends(data) {
  const response = await userAxios.post('/invitations', data);
  if (response?.data) {
    return response.data;
  }
}

export async function deleteResearch(id) {
  const response = await userAxios.delete('/researches/' + id);
  if (response?.data) {
    return response.data;
  }
}

export async function deleteVariable(id) {
  const response = await userAxios.delete('/variables/' + id);
  if (response?.data) {
    return response.data;
  }
}

export async function updateProject({ projectId, ...data }) {
  const response = await userAxios.patch('/projects/' + projectId, data);
  if (response?.data) {
    return response.data;
  }
}

export async function deleteProject(projectId) {
  const response = await userAxios.delete('/projects/' + projectId);
  if (response?.data) {
    return response.data;
  }
}

export async function getUserTags() {
  const response = await userAxios.get('/tags');
  if (response?.data) {
    return response.data;
  }
}

export async function createProjectTag(data) {
  const response = await userAxios.post('/tags', data);
  if (response?.data) {
    return response.data;
  }
}

export async function updateTag(tagId, data) {
  const response = await userAxios.patch('/tags/' + tagId, data);
  if (response?.data) {
    return response.data;
  }
}

export async function deleteTag(tagId) {
  const response = await userAxios.delete('/tags/' + tagId);
  if (response?.data) {
    return response.data;
  }
}

export async function updateUserResearch(researchId, data) {
  const response = await userAxios.patch(`/researches/${researchId}`, data);
  if (response?.data) {
    return response.data;
  }
}

export async function updateUserVariable(variableId, data) {
  const response = await userAxios.patch(`/variables/${variableId}`, data);
  if (response?.data) {
    return response.data;
  }
}

const defaultState = {
  projects: null,
  researches: null,
  variables: null
};

const SET_PROJECTS_CONFIG = 'SET_PROJECTS_CONFIG';
const SET_RESEARCHES_CONFIG = 'SET_RESEARCHES_CONFIG';
const SET_VARIABLES_CONFIG = 'SET_VARIABLES_CONFIG';

export const setProjectsConfig = (payload) => ({
  type: SET_PROJECTS_CONFIG,
  payload
});

export const setResearchesConfig = (payload) => {
  return {
    type: SET_RESEARCHES_CONFIG,
    payload
  };
};

export const setVariablesConfig = (payload) => {
  return {
    type: SET_VARIABLES_CONFIG,
    payload
  };
};

export function appConfigReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_PROJECTS_CONFIG:
      return { ...state, projects: action.payload };
    case SET_RESEARCHES_CONFIG:
      return { ...state, researches: action.payload };
    case SET_VARIABLES_CONFIG:
      return { ...state, variables: action.payload };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import classes from './login.module.scss';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { ReactComponent as LogoIcon } from '../../assets/svg/Icon.svg';
import { ReactComponent as AnalystPro } from '../../assets/svg/AnalystPro.svg';
import { formConfig } from '../reset-password/module';
import { getResetToken } from '../../api/auth';
import { notify } from '../../components/Snackbar';
import { useNavigate } from 'react-router-dom';

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const [screan, setScrean] = useState(1);

  const { values, errors, handleSubmit, handleChange, validateField } = useFormik({
    ...formConfig.resetPassword,
    onSubmit: (values) => {
      getResetToken({ email: values.email }).then(() =>
        notify.success('Success!')
      );
    }
  });

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSubmit();
  };

  function handleSubmitFirst() {
    setScrean(2);
    handleSubmit();
  }

  function goSignin() {
    navigate('/signin');
  }

  return (
    <div>
      <header className={classes.appbar}>
        <div className={classes.logo}>
          <LogoIcon />
          <AnalystPro />
        </div>
      </header>
      <div className={classes.center}>
        {screan === 1 ? (
          <div className={classes.main} onKeyPress={handleKeyPress}>
            <form className="container" onSubmit={handleSubmitFirst}>
              <h2 className="fontOutfit title text-center mb-1">
                Reset password
              </h2>
              <p className="fontOutfit text bold mb-8">Work email address</p>

              <TextField
                name="email"
                type="email"
                className="w-100 mb-1"
                placeholder="E.g. you@company.com"
                value={values.email}
                onChange={async (e) => {
                  await handleChange(e);
                  await validateField('email');
                }}
                helperText={errors.email}
                error={!!errors.email}
              />
              <Button type="submit" className="w-100 mb-1">
                Continue
              </Button>
            </form>
          </div>
        ) : (
          <div className={classes.main}>
            <div className="container">
              <h2 className="fontOutfit title text-center mb-1">Success</h2>
              <div className="fontOutfit text mb-1">
                Please check you email for the Reset Password link. Allow up to
                10 minutes for the email to arrive.
              </div>
              <Button className="w-100 mb-1" onClick={goSignin}>
                Continue
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

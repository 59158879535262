import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top" {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#031927'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#031927',
    fontFamily: 'Outfit',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 14,
    lineHeight: '20px',
    maxWidth: 320
  }
}));

import React from 'react';
import { Button } from '@mui/material';
export default function EmailVerified({ handleClose }) {
  return (
    <div className="main">
      <div className="container">
        <h2 className="fontOutfit title text-center mb-1">Email verified</h2>
        <div className="fontOutfit text mb-1">
          Your account has been verified. Continue by logging in.
        </div>
        <Button className="w-100 mb-1 " onClick={handleClose}>
          Okay
        </Button>
      </div>
    </div>
  );
}

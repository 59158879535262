import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { notify } from '../../components/Snackbar';
import { createProject, getUserProjects } from '../../api/user';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProjectsAction } from '../../store/reducers/user';
import classes from './create-project.module.scss';
import { ReactComponent as CreateProjectEmoji } from '../../assets/svg/CreateProjectEmoji.svg';
import { AnalyticsEvent } from '../../utils/analytics';

export function CreateProject() {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleChange(e) {
    const { value } = e.target;
    setName(value);
  }

  let projectId = null;

  function createNewProject() {
    if (!name) return notify.warning('Enter project name!');
    if (name.length > 25) return notify.warning('Project name is too long!');
    createProject({ name })
      .then((project) => {
        setName('');
        projectId = project.id;
        return getUserProjects();
      })
      .then(({ data }) => {
        dispatch(setProjectsAction(data));
        navigate('/research/' + projectId);
      });
  }

  const createProjectClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'create_project_submit_button_click'
    },
    createNewProject
  );

  return (
    <div className="main centered transparent">
      <div className={classes.container}>
        <div className={classes.row}>
          <h2 className={classes.h2CreateProject}>Welcome</h2>
          <div className={classes.rowEmoji}>
            <CreateProjectEmoji />
          </div>
        </div>

        <div className={classes.empty}>
          Enter the name of your project.
          <br />
          You can change this later.
        </div>
        <div className={classes.center}>
          <TextField
            size="small"
            className="w-100 mb-1"
            placeholder="Enter project name"
            onChange={handleChange}
            value={name}
          />
          <Button className="w-100 mb-1" onClick={createProjectClick}>
            Create project
          </Button>
        </div>
      </div>
    </div>
  );
}

import { hotjar } from "react-hotjar";

export function initHotjar() {
  // Initialize Google Analytics
  console.log('Initialise Hotjar');
  const hjid = Number.parseInt(process.env.REACT_APP_HJID);
  const hjsv = Number.parseInt(process.env.REACT_APP_HJSV);
  hotjar.initialize(hjid, hjsv);
}

export function hotjarEvent(eventName){
  hotjar.event(eventName);
}

export function hotjarIdentifyUser(user){
  hotjar.identify(user.id, {});
}

export function hotjarPageChanges(pathname){
  hotjar.stateChange(pathname);
}
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { inputBaseClasses } from '@mui/material';

const palette = {
  tab: '#7F7F7F',
  tabActive: '#313131'
};

const xsTextField = {
  props: { size: 'xs' },
  style: {
    [`& .${inputBaseClasses.root}`]: {
      height: 32,
      fontSize: 12
    },
    [`& .${inputBaseClasses.input}`]: {
      padding: '8px 10px'
    }
  }
};

const xsSelect = {
  props: { size: 'xs' },
  style: {
    height: 32,
    fontSize: 12,
    [`& .${inputBaseClasses.input}`]: {
      padding: '8px 10px'
    }
  }
};

const graySelect = {
  props: { bg: 'gray' },
  style: {
    backgroundColor: '#edeeef'
  }
};

const grayField = {
  props: { bg: 'gray' },
  style: {
    [`& .${inputBaseClasses.root}`]: {
      backgroundColor: '#edeeef'
    }
  }
};

const theme = createTheme({
  typography: {
    fontFamily: `"Outfit", "sans-serif"`
  },

  palette: {
    error: {
      main: '#DE005B'
    },
    primary: {
      main: '#4985C5'
    },
    black: {
      main: '#031927'
    }
  },

  props: {
    MuiButtonBase: {
      disableRipple: false
    },

    MuiPopover: {
      elevation: 1
    }
  },

  components: {
    MuiButton: {
      defaultProps: {
        color: 'error',
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          height: 42,
          fontSize: 16,
          fontWeight: 700,
          textTransform: 'none',
          letterSpacing: '0.03em',
          lineHeight: '22px'
        }
      }
    },
    MuiTextField: {
      variants: [xsTextField, grayField],
      defaultProps: {
        autoComplete: 'off',
        size: 'small',
        label: ''
      }
    },
    MuiSelect: {
      variants: [xsSelect, graySelect],
      defaultProps: {
        size: 'small'
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: palette.tab,
          '&.Mui-selected': {
            color: palette.tabActive
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: palette.tabActive
          }
        }
      }
    }
  }
});

export function MuiThemeProvider(props) {
  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import classes from './app-menu.module.scss';
import { ReactComponent as PlusIcon } from '../assets/svg/Plus.svg';
import { ReactComponent as LogoIcon } from '../assets/svg/Icon.svg';
import { ReactComponent as AnalystPro } from '../assets/svg/AnalystPro.svg';
import { ReactComponent as AddUser } from '../assets/svg/AddUser.svg';
import { ReactComponent as QuestionMark } from '../assets/svg/outlined/question-mark.svg';
import { ModalProvider } from './Modal';
import { InviteFriends } from '../views/invite-friends/InviteFriends';
import { InvitationSent } from '../views/invite-friends/InvitationSent';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectName, stringToColor } from '../utils/utils';
import { AnalyticsEvent } from '../utils/analytics';
import {
  ModalComponents,
  useModalComponents
} from '../views/modals/ModalComponents';

function AppBar() {
  const [openInvite, setOpenInvite] = useState(false);
  const [step, setStep] = useState(0);

  function handleOpenInvite() {
    setOpenInvite(() => true);
  }

  function handleCloseInvite() {
    setStep(0);
    setOpenInvite(() => false);
  }

  function handleSubmit() {
    if (step < 1) {
      setStep((step) => step + 1);
    } else {
      setStep(0);
    }
  }

  function InviteFriendFlow() {
    switch (step) {
      case 0:
        return (
          <InviteFriends onSubmit={handleSubmit} onClose={handleCloseInvite} />
        );
      case 1:
        return (
          <InvitationSent onSubmit={handleSubmit} onClose={handleCloseInvite} />
        );
      default:
        return null;
    }
  }

  const user = useSelector(({ user }) => user);
  const navigate = useNavigate();

  const settingsClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'settings_button_click'
    },
    () => {
      navigate('/settings');
    }
  );

  const openInviteClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'invite_friends_button_click'
    },
    handleOpenInvite
  );

  return (
    <header className={classes.appbar}>
      <NavLink to="/home" className={classes.logo}>
        <LogoIcon />
        <AnalystPro />
      </NavLink>

      <div className={classes.toolbar}>
        <IconButton className={classes.item} onClick={openInviteClick}>
          <AddUser />
        </IconButton>
        <div className={classes.user} onClick={settingsClick}>
          {user.name}
        </div>
      </div>
      <ModalProvider open={openInvite} onClose={handleCloseInvite}>
        <InviteFriendFlow />
      </ModalProvider>
    </header>
  );
}

function Sidebar() {
  const navigate = useNavigate();
  const { modal, modals, modalOpen, handleModalClose, openModal } =
    useModalComponents();
  const projects = useSelector(({ user }) => user.projects) || [];

  const projectClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'open_project_button_click'
    },
    (project) => navigate('/research/' + project.id)
  );

  const createProjectClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'create_project_button_click'
    },
    () => navigate('/create-project')
  );

  return (
    <nav className={classes.sidebar}>
      <IconButton className={classes.new} onClick={createProjectClick}>
        <PlusIcon />
      </IconButton>
      <div className={classes.projects}>
        {projects.slice(0, 7).map((project, key) => {
          const backgroundColor = stringToColor(project.name),
            shortName = getProjectName(project.name);
          return (
            <IconButton
              key={key}
              className={classes.project}
              style={{ backgroundColor }}
              onClick={() => projectClick(project)}
            >
              {shortName}
            </IconButton>
          );
        })}
      </div>
      <QuestionMark
      style={{cursor: "pointer"}}
        className={classes.config}
        onClick={() => openModal(modals.sidebarHelp, { handleModalClose })}
      />
      <ModalComponents
        modal={modal}
        open={modalOpen}
        onClose={handleModalClose}
      />
    </nav>
  );
}

function Content({ children }) {
  return <div className={classes.app}>{children}</div>;
}

export function AppMenu({ children }) {
  return (
    <div className={classes.content}>
      <AppBar />
      <Sidebar />
      <Content>{children}</Content>
    </div>
  );
}

import axios from 'axios';
import { env as config } from '../config';
import { notify } from '../components/Snackbar';

export const guestAxios = axios.create({
  baseURL: config.APIbaseURL,
  timeout: 5000
});

guestAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMessage = error.message;
    if (error.response) {
      const { data, config } = error.response;
      if (data.message) errorMessage = data.message;
      console.error(data, config);
    }
    notify.error(errorMessage || 'Server error!');
    return Promise.reject(error);
  }
);

export let userAxios;

export function initUserAxiosInstance({ token, logoutAction }) {
  const unauthorizedStatusCode = 401;

  userAxios = axios.create({
    baseURL: config.APIbaseURL,
    timeout: 5000,
    headers: {
      accept: 'application/json',
      token
    }
  });

  userAxios.interceptors.response.use(
    (response) => {
      const { config } = response;
      const { method } = config;
      if (method !== 'get') {
        notify.success('Success!');
      }
      return response;
    },

    (error) => {
      const { status } = error.response;
      if (status === unauthorizedStatusCode) {
        logoutAction();
      } else {
        const { data, config } = error.response;
        console.error(data, config);
        notify.error(data.message || 'Server error!');
      }
      return Promise.reject(error);
    }
  );
}

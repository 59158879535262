import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './store';
import { createBrowserHistory } from 'history';
import App from './App';
import './index.css';

const history = createBrowserHistory();
ReactDOM.render(
  <React.StrictMode>
    <App store={store} history={history} />
  </React.StrictMode>,
  document.getElementById('root')
);

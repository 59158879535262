import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import classes from './settings.module.scss';
import { logOutAction } from '../../store/reducers/user';
import { useDispatch } from 'react-redux';
import { PersonalInfo } from './PersonalInfo';
import { LoginAndSecurity } from './LoginAndSecurity';
import { TeamAndPrivacy } from './TeamAndPrivacy';
import { AnalyticsEvent } from '../../utils/analytics';

export function Settings() {
  const info = 0,
    privacy = 1,
    security = 2,
    subscription = 3;

  useEffect(() => {
    document.title = "Settings | AnalystPro";
  }, []);

  function ComponentSwitch() {
    switch (current) {
      case info:
        return <PersonalInfo />;
      case privacy:
        return <TeamAndPrivacy />;
      case security:
        return <LoginAndSecurity />;
      case subscription:
        return null;
      default:
        return null;
    }
  }

  const [current, setCurrent] = useState(info);
  const dispatch = useDispatch();

  function handleLogOut() {
    dispatch(logOutAction());
  }

  const personalInfoClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'current_user_info_button_click'
    },
    () => setCurrent(info)
  );

  const privacyClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'privacy_button_click'
    },
    () => setCurrent(privacy)
  );

  const securityClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'security_button_click'
    },
    () => setCurrent(security)
  );

  const logoutClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'logout_button_click'
    },
    handleLogOut
  );

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <h2 className="fontOutfit title">Settings</h2>
        <div className={classes.content}>
          <div className={classes.options}>
            <Button
              className="w-100"
              variant="outlined"
              onClick={personalInfoClick}
            >
              Personal info
            </Button>
            <Button className="w-100" variant="outlined" onClick={privacyClick}>
              Team & privacy
            </Button>
            <Button
              className="w-100"
              variant="outlined"
              onClick={securityClick}
            >
              Login & security
            </Button>
            <Button className="w-100" variant="text" onClick={logoutClick}>
              Log out
            </Button>
          </div>
          <ComponentSwitch />
        </div>
      </div>
    </div>
  );
}

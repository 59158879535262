import React from 'react';
import { Button, Stack, Box } from '@mui/material';

export function SidebarHelp({handleModalClose}) {
  const handleFAQ = () => {
    window.open("https://oasis-hornet-cdc.notion.site/FAQs-5caf1e722d694d658f20a0f0a4b92831")
  }

  const handleTutorial = () => {
    window.open("https://vimeo.com/733680567")
  }

  const handleSupport = () => {
  window.open(
        "mailto:hello@analystpro.io?subject=Help%20%2F%20Report%20Bug%20%2F%20Feature%20Request"
      );
  }
  return (
    <React.Fragment>
      <Stack
      sx={{ marginBottom: "80px" }}
        direction="row"
        alignItems="center"
        justifyContent="center">
        <div className="AN-popup-title">Settings</div>
      </Stack>
      <Box className="AN-settingsButton" sx={{ padding: '0px !important' }}>
        <Button
          className="main-button-popup"
          variant="contained"
          onClick={handleFAQ}>
          Read FAQs
        </Button>
        <Button
          className="secondary-button-popup"
          onClick={handleTutorial}
          variant="outlined">
          Watch tutorial
        </Button>

        <Button sx={{ marginTop: "24px", color: "#031927 !important", textTransform: "inherit !important" }}
        onClick={handleSupport}
          variant="text">
          Contact support
        </Button>
        <Button variant="text" className="AN-margin-top" onClick={handleModalClose} sx={{ color: "#031927 !important" }}>
          Dismiss
        </Button>
      </Box>
    </React.Fragment>
  );
}

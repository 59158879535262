import { guestAxios } from '../utils/axios';

export async function signUp(data) {
  const response = await guestAxios.post('/register', data);
  if (response?.data) {
    return response.data;
  }
}

export async function logIn(data) {
  const response = await guestAxios.post('/login', data);
  if (response?.data) {
    return response.data;
  }
}

export async function getInvitationData(invitation) {
  const response = await guestAxios.get('/invitations/' + invitation);
  if (response?.data) {
    return response.data;
  }
}

export async function getResetToken(data) {
  const response = await guestAxios.post('/users/password/reset', data);
  if (response?.data) {
    return response.data;
  }
}

export async function resetUserPassword(data) {
  const response = await guestAxios.put('/users/password', data);
  if (response?.data) {
    return response.data;
  }
}

export async function resendVerificationLink(email) {
  const response = await guestAxios.patch('/verification', email);
  if (response?.data) {
    return response.data;
  }
}

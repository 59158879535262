import React from 'react';
import { Button } from '@mui/material';

export function SuccessResetPassword({ onSubmit }) {
  return (
    <div className="main">
      <div className="container">
        <h2 className="fontOutfit title text-center mb-1">Success</h2>
        <div className="fontOutfit text mb-1">
          Please check your email address with the email we sent to you.
        </div>
        <Button className="w-100 mb-1" onClick={onSubmit}>
          Continue
        </Button>
      </div>
    </div>
  );
}

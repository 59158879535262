import React from 'react';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { setEmailAction } from '../../store/reducers/user';
import { formConfig } from './module';

export function SignUp({ onSubmit }) {
  const dispatch = useDispatch();
  const { values, errors, handleSubmit, handleChange, handleBlur } = useFormik({
    ...formConfig.signUp,
    onSubmit: (values) => {
      dispatch(setEmailAction(values.email));
      onSubmit();
    }
  });

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSubmit();
  };

  return (
    <div className="main" tabIndex="0" onKeyPress={handleKeyPress}>
      <form className="container" onSubmit={handleSubmit}>
        <h2 className="fontOutfit title text-center mb-1">Sign up</h2>
        <p className="fontOutfit text bold mb-8">Work email address</p>

        <TextField
          name="email"
          type="email"
          className="w-100 mb-1"
          placeholder="E.g. you@company.com"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.email}
          error={!!errors.email}
        />
        <Button type="submit" className="w-100 mb-1">
          Continue
        </Button>
      </form>
    </div>
  );
}

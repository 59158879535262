import React, { useEffect, useState } from 'react';
import { guestAxios } from '../../utils/axios';
import { notify } from '../../components/Snackbar';
import { Button } from '@mui/material';

export function AccountVerification({ onSubmit, token }) {
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    token &&
      guestAxios.post('/verification', { token }).then(() => {
        setVerified(true);
        notify.success('Account has been verified!');
      });
  }, [token]);

  return (
    <div className="main">
      <div className="container">
        <h2 className="fontOutfit title text-center mb-1">
          Account verification
        </h2>
        <div className="fontOutfit text mb-1">
          Thank you for signing up!
          <br />
          <br />
          Your account is currently pending verification. You can log in as soon
          as it's verified.
        </div>
        <Button className="w-100 mb-1" disabled={!verified} onClick={onSubmit}>
          Log In
        </Button>
      </div>
    </div>
  );
}

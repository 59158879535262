import './App.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from './views/AppTheme';
import { SnackbarProvider } from 'notistack';
import { appVersion, snack } from './config';
import { AppRoutes } from './AppRoutes';
import { SnackbarUtils } from './components/Snackbar';

import { initAnalytics, setPageStateChanges} from "./utils/analytics";

function App({ store, history }) {
  console.log('App Version: ' + appVersion);
  initAnalytics();

  history.listen((location, action) => {
    setPageStateChanges(location.pathname);
  });
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider>
          <SnackbarProvider {...snack}>
            <SnackbarUtils />
            <AppRoutes />
          </SnackbarProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Button, Checkbox, TextField, FormControlLabel, Link } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getInvitationData, signUp } from '../../api/auth';
import { notify } from '../../components/Snackbar';
import { formConfig } from './module';
import { setEmailAction, setInvitationAction } from '../../store/reducers/user';
import { setIdentity } from '../../utils/hubspotAnalytics';

export function SignUpForm({ onSubmit, invitation = undefined }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(!!invitation);
  const [newPassword, setNewPassword] = useState(false);
  const email = useSelector(({ user }) => user.email);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    validateField
  } = useFormik({
    ...formConfig.signUpForm,
    onSubmit: (values) => {
      setLoading(true);
      signUp({
        name: values.name,
        company: values.company,
        role: values.role,
        secret: values.password,
        accountType: 'custom',
        isSubscribed: values.subscribed,
        invitation,
        email
      })
        .then(() => {
          notify.success('Account has been created!');
          setIdentity(email, {});
          onSubmit();
        })
        .finally(() => setLoading(false));
    }
  });

  useEffect(() => {
    if (values.repeat) {
      validateField('repeat');
    }
  }, [values.password]);

  useEffect(() => {
    invitation &&
      getInvitationData(invitation)
        .then(({ inviteeEmail, id, isUsed }) => {
          if (isUsed) return notify.warning('Invalid invitation!');
          dispatch(setInvitationAction(id));
          dispatch(setEmailAction(inviteeEmail));
        })
        .finally(() => setLoading(false));
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSubmit();
  };

  function getFieldProps(name, onChange = false) {
    return {
      name: name,
      value: values[name],
      helperText: errors[name],
      error: !!errors[name],
      autoComplete: 'new-password',
      onChange: async (e) => {
        await handleChange(e);
        if (onChange) await validateField(name);
      },
      onBlur: async (e) => {
        await handleBlur(e);
        if (!onChange) await validateField(name);
      }
    };
  }

  const toggleNew = () => {
    setNewPassword(!newPassword);
  };

  return (
    <div className="main" tabIndex="0" onKeyPress={handleKeyPress}>
      <form className="container" onSubmit={handleSubmit}>
        <h2 className="fontOutfit title text-center mb-1">Sign up</h2>
        <TextField
          className="w-100 mb-4"
          placeholder="Name"
          {...getFieldProps('name')}
        />
        <TextField
          className="w-100 mb-4"
          placeholder="Company"
          {...getFieldProps('company')}
        />
        <TextField
          className="w-100 mb-1"
          placeholder="Role"
          {...getFieldProps('role')}
        />
        <TextField
          name="email"
          className="w-100 mb-1 italic"
          placeholder="Email"
          value={email}
          disabled
        />
        <TextField
          className="w-100 mb-4"
          placeholder="Password"
          type={newPassword ? 'text' : 'password'}
          {...getFieldProps('password', 1)}
        />
        <TextField
          className="w-100"
          placeholder="Repeat Password"
          type={newPassword ? 'text' : 'password'}
          {...getFieldProps('repeat', 1)}
        />
        <FormControlLabel
          className="mb-1"
          checked={newPassword}
          onChange={toggleNew}
          control={
            <Checkbox
              size="small"
              style={{
                margin: '2px 8px 2px 12px',
                padding: 0
              }}
            />
          }
          label="Show password"
          labelPlacement="end"
          value="end"
        />

        <div className="mb-1 d-flex align-start">
          <Checkbox
            disableRipple
            size="small"
            type="checkbox"
            name="subscribed"
            checked={values.subscribed}
            onChange={handleChange}
            style={{ marginTop: 2, marginRight: 8, padding: 0 }}
          />
          <div className="fontOutfit text-xs">
            I’d like to receive the occasional product update, digestible
            newsletter or cool research tricks.
          </div>
        </div>

        <div className="fontOutfit mb-1 text-xs">
          By selecting <span className="bold">Agree and continue</span> below, I
          agree to AnalystPro’s{' '}
          <Link
            href="https://drive.google.com/file/d/1iRTbX_KcSgTvAu0Bw3JN_V3ylLnApLm-/view?usp=sharing"
            target="_blank"
          >
            Terms of Use
          </Link>{' '}
          and{' '}
          <Link
            href="https://drive.google.com/file/d/10v5lki1vxvnaYoYELzf_558ujB839PE4/view?usp=sharing"
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </div>
        <Button className="w-100" type="submit" disabled={loading}>
          Agree and continue
        </Button>
      </form>
    </div>
  );
}

import * as hotjarAnalytics from "./hotjar-analytics";
import * as googleAnalytics from "./google-analytics";
import * as hubspotAnalytics from "./hubspotAnalytics";

export function initAnalytics(){
  googleAnalytics.initGoogleAnalytics();
  hotjarAnalytics.initHotjar();
  hubspotAnalytics.initHubspotAnalytics();
}

export function identifyUserForAnalytics(user){
  googleAnalytics.googleIdentifyUser(user);
  hotjarAnalytics.hotjarIdentifyUser(user);
}

export function AnalyticsEvent(analyticsOptions, decoratedCallback){
  return function (...args) {
    googleAnalytics.sendEvent(analyticsOptions);
    hotjarAnalytics.hotjarEvent(analyticsOptions.action);
    return decoratedCallback.apply(this, args);
  };
}

export function setPageStateChanges(pathname){
  googleAnalytics.setPageChanges(pathname);
  hotjarAnalytics.hotjarPageChanges(pathname);
}
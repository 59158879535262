import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Button } from '@mui/material';
import classes from './popups.module.scss';
import { notify } from '../../components/Snackbar';
import * as _ from 'lodash';

export function ChangeColumns({ columns, onCancel, onConfirm }) {
  const defaultState = _.cloneDeep(columns);
  const [controls, setControls] = useState(defaultState);

  function handleChange(e) {
    const { name, checked } = e.target;
    const control = controls.find((c) => c.id === name);
    control.active = checked;
    setControls((prev) => [...prev]);
  }

  function handleConfirm() {
    const activeControls = controls.filter((c) => c.active);
    if (activeControls.length) {
      onConfirm(controls);
    } else {
      notify.warning('Select at least 1 option');
    }
  }

  return (
    <div className={classes.changeColumns}>
      <h3 className="fontOutfit">Edit columns</h3>
      <h4 className="fontOutfit">Select columns</h4>
      <FormGroup>
        {controls.map((col) => (
          <FormControlLabel
            className={classes.label}
            key={col.label}
            control={
              <Checkbox
                name={col.id}
                checked={col.active}
                onChange={handleChange}
              />
            }
            label={col.label}
          />
        ))}
      </FormGroup>
      <div className={classes.buttonGroup}>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          className={classes.confirm}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

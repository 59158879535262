import React from 'react';
import classes from './login.module.scss';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { setEmailAction } from '../../store/reducers/user';
import { formConfig } from '../sign-up/module';
import { useNavigate } from 'react-router-dom';

export function SignUpEmailPage({ handleSubmitFirst, setForm }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values, errors, handleSubmit, handleChange, validateField } =
    useFormik({
      ...formConfig.signUp,
      onSubmit: (values) => {
        dispatch(setEmailAction(values.email));
        setForm({
          name: '',
          company: '',
          role: '',
          secret: '',
          accountType: 'custom',
          invitation: undefined,
          email: values.email,
          isSubscribed: false
        });
        handleSubmitFirst();
      }
    });

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSubmit();
  };

  function goSignin() {
    navigate('/signin');
  }

  return (
    <div className={classes.center}>
      <div className={classes.main} onKeyPress={handleKeyPress}>
        <form className="container" onSubmit={handleSubmit}>
          <h2 className="fontOutfit title text-center mb-1">Sign up</h2>
          <p className="fontOutfit text mb-1 mt-1">Boost your research by up to 50%. Get started now by creating your free account.</p>

          <TextField
            name="email"
            type="email"
            className="w-100 mb-1"
            placeholder="Enter work email"
            value={values.email}
            onChange={async (e) => {
              await handleChange(e);
              await validateField('email');
            }}
            helperText={errors.email}
            error={!!errors.email}
          />
          <Button type="submit" className="w-100 mb-1">
            Continue
          </Button>
          <Button
            variant="text"
            className="w-100 mb-1 goSignup"
            onClick={goSignin}
          >
            I have an account
          </Button>
        </form>
      </div>
    </div>
  );
}

import React from 'react';
import classes from './settings.module.scss';

export function TeamAndPrivacy() {
  return (
    <div className={classes.paper} style={{ height: 154 }}>
      <h2 className="fontOutfit title">Coming soon!</h2>
    </div>
  );
}

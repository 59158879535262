import * as Yup from 'yup';

export const formConfig = {
  resetPassword: {
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is a required field')
    }),
    validateOnBlur: true,
    validateOnChange: false
  }
};

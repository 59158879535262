import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { notify } from '../../components/Snackbar';
import { createProject, getUserProjects } from '../../api/user';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFirstLogIn, setProjectsAction } from '../../store/reducers/user';

export function CreateFirstProject() {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleChange(e) {
    const { value } = e.target;
    setName(value);
  }

  let projectId = null;

  function createNewProject() {
    if (!name) return notify.warning('Enter project name!');
    if (name.length > 25) return notify.warning('Project name is too long!');
    createProject({ name })
      .then((project) => {
        setName('');
        projectId = project.id;
        return getUserProjects();
      })
      .then(({ data }) => {
        dispatch(setProjectsAction(data));
        dispatch(setFirstLogIn(true));
        navigate('/research/' + projectId);
      });
  }

  return (
    <div className="main centered">
      <div className="container-getting-started">
        <h2 className="second-title text-center mb-1 w-100">
          Create my first project (3/4){' '}
        </h2>
        <p className="text text-left mb-1 ml-6">
          Decide on a topic you would like to <br />
          research. Enter the name of that project. <br />
          Don’t worry, you can change this later.
        </p>
        <div className="text-center">
          <TextField
            size="small"
            className="w-100 mb-1"
            placeholder="Enter project name"
            onChange={handleChange}
            value={name}
          />
          <Button className="w-100 mb-1" onClick={createNewProject}>
            Create project
          </Button>
        </div>
      </div>
    </div>
  );
}

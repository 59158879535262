import React, { useState } from 'react';
import classes from '../modals/add-notes.module.scss';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import { tagColors } from './module';

export function UpdateTag({ onSubmit, onCancel, tag, projectId }) {
  const initialState = {
    name: tag.name,
    color: tag.color,
    projectId: projectId
  };

  const [form, setForm] = useState(initialState);
  const handleUpdateClick = () => {
    onSubmit(form);
  };

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={`fontOutfit ${classes.header}`}>
          <div className={`fontOutfit ${classes.title}`}>Update Tag</div>
        </div>
        <div className={classes.body}>
          <div className={`${classes.item} ${classes.marginBottom}`}>
            <div className={`fontOutfit ${classes.label}`}>Name</div>
            <TextField
              name="name"
              placeholder="Enter name"
              className={classes.fieldUpdateTag}
              value={form.name}
              onChange={handleFormChange}
            />
          </div>
          <div className={classes.item}>
            <div className={`fontOutfit ${classes.label}`}>Color</div>
            <Select
              name="color"
              className={classes.fieldUpdateTag}
              value={form.color}
              onChange={handleFormChange}
            >
              {tagColors.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            variant="text"
            className={classes.updateTag}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            className={classes.updateTag}
            onClick={handleUpdateClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

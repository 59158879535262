import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { updateProject } from '../../api/user';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { notify } from '../../components/Snackbar';
import { setProjectsAction } from '../../store/reducers/user';

export function ChangeProjectName({ onSubmit }) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [name, setProjectName] = useState('');
  const projects = useSelector(({ user }) => user.projects);
  const [loading, setLoading] = useState(false);

  function handleNameChange() {
    if (!name.length) return notify.warning('Project name can`t be blank!');
    if (name.length > 40) return notify.warning('Project name is too long!');
    setLoading(true);
    updateProject({
      projectId,
      name
    })
      .then((project) => {
        setProjectName('');
        const currentProject = projects.find((p) => p.id === project.id);
        currentProject.name = project.name;
        dispatch(setProjectsAction([...projects]));
        onSubmit && onSubmit();
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="main">
      <div className="container">
        <h2 className="fontOutfit title text-center mb-1">
          Change project name
        </h2>
        <TextField
          className="w-100 mb-1"
          placeholder="New project name"
          onChange={(e) => setProjectName(e.target.value)}
          value={name}
        />
        <Button
          type="submit"
          className="w-100 mb-1"
          disabled={loading}
          onClick={handleNameChange}
        >
          Change name
        </Button>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { formConfig } from './module';
import { inviteFriends } from '../../api/user';
import { AnalyticsEvent } from '../../utils/analytics';

export function InviteFriends({ onSubmit, onClose }) {
  const initialValues = [{ email: '' }];
  const [inputList, setInputList] = useState(initialValues);

  const { values, errors, handleSubmit, setValues, handleBlur } = useFormik({
    ...formConfig.inviteForm,
    onSubmit: AnalyticsEvent(
      {
        category: 'user_click',
        action: 'invite_friends_submit_button_click'
      },
      (values) => {
        const invitees = values.map((e) => e.email);
        inviteFriends({ invitees });
        setInputList(initialValues);
        onSubmit();
      }
    )
  });

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index].email = value;
    setInputList(list);
    setValues(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { email: '' }]);
  };

  const addMoreFriendsClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'add_more_friends_button_click'
    },
    handleAddClick
  );

  return (
    <div className="main">
      <form className="container w-100" onSubmit={handleSubmit}>
        <h2 className="fontOutfit title text-center mb-1">Invite friend</h2>
        {inputList.map((el, i) => {
          return (
            <TextField
              key={i}
              name="email"
              type="email"
              className="w-100 mb-1"
              placeholder="Enter email address"
              value={el.email}
              onChange={(e) => handleInputChange(e, i)}
              onBlur={handleBlur}
              helperText={errors[i]?.email}
              error={!!errors[i]?.email}
            />
          );
        })}

        <Button
          variant="outlined"
          className="w-100 mb-1"
          onClick={addMoreFriendsClick}
        >
          Add another
        </Button>
        <Button type="submit" className="w-100 mb-1">
          Send invitation
        </Button>
        <Button
          color="black"
          variant="text"
          className="w-100 mb-1"
          onClick={onClose}
        >
          Later
        </Button>
      </form>
    </div>
  );
}

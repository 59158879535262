import React, { useState } from 'react';
import classes from './settings.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from '../../api/user';
import { setUserAction } from '../../store/reducers/user';
import { notify } from '../../components/Snackbar';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { containsValue, lsUpdateUser, mapEmpty } from '../../utils/utils';
import { data } from './module';
import { AnalyticsEvent } from '../../utils/analytics';

export function PersonalInfo() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const [personalInfo, setPersonalInfo] = useState(data.personalInfo);
  const [loading, setLoading] = useState(false);

  function changePersonalInfo(e) {
    const { name, value } = e.target;
    setPersonalInfo((values) => ({
      ...values,
      [name]: value
    }));
  }

  function updateUser() {
    if (!containsValue(personalInfo))
      return notify.warning('At least one field is required!');
    setLoading(true);
    updateUserInfo(mapEmpty(personalInfo))
      .then((user) => {
        dispatch(setUserAction(user));
        setPersonalInfo(data.personalInfo);
        lsUpdateUser(user);
      })
      .finally(() => setLoading(false));
  }

  const updateUserClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'update_user_profile_button_click'
    },
    updateUser
  );

  return (
    <div className={classes.paper}>
      <h2 className="fontOutfit title">Personal info</h2>

      <h4 className="fontOutfit">Name</h4>
      <TextField
        name="name"
        className="w-100"
        placeholder={user.name}
        onChange={changePersonalInfo}
        value={personalInfo.name}
      />

      <h4 className="fontOutfit">Role</h4>
      <TextField
        name="role"
        className="w-100"
        placeholder={user.role}
        onChange={changePersonalInfo}
        value={personalInfo.role}
      />

      <h4 className="fontOutfit">Company</h4>
      <TextField
        name="company"
        className="w-100"
        placeholder={user.company}
        onChange={changePersonalInfo}
        value={personalInfo.company}
      />

      <Button
        disabled={loading}
        variant="outlined"
        onClick={updateUserClick}
        className={classes.buttonSm}
      >
        Save
      </Button>
    </div>
  );
}

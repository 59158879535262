import React, { useState } from 'react';
import classes from './popups.module.scss';

import {
  FormControlLabel,
  Button,
  RadioGroup,
  TextField,
  Radio
} from '@mui/material';
import { notify } from '../../components/Snackbar';

export function TableFilter({ onCancel, onConfirm, filters }) {
  const [selectedValue, setSelectedValue] = useState(null);
  const [filterPhrase, setFilterPhrase] = useState('');

  function handleChange(e) {
    setSelectedValue(e.target.value);
  }

  function handleConfirm() {
    if (!selectedValue) return notify.warning('Select at least 1 option!');
    if (!filterPhrase)
      return notify.warning('Filter value is a required field!');
    onConfirm(selectedValue, filterPhrase);
  }

  return (
    <div className={classes.changeColumns}>
      <h3 className="fontOutfit">Filter</h3>
      <h4 className="fontOutfit">Select columns</h4>
      <RadioGroup>
        {filters.map((f) => (
          <FormControlLabel
            key={f.id}
            value={f.label}
            control={
              <Radio
                name={f.id}
                value={f.id}
                checked={selectedValue === f.id}
                onChange={handleChange}
              />
            }
            label={f.label}
          />
        ))}
      </RadioGroup>

      {/* mt16 mb 6*/}
      <h4 className="fontOutfit">Filter by</h4>
      <TextField
        className="w-100"
        placeholder="Value"
        value={filterPhrase}
        onChange={(e) => setFilterPhrase(e.target.value)}
      />

      <div className={classes.buttonGroup}>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          className={classes.confirm}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

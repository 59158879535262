import React from 'react';
import { Button } from '@mui/material';

export function ExportModalConfirm({ onClose }) {
  return (
    <div className="main">
      <form className="container w-100">
        <h2 className="fontOutfit text-export text-center mb-1">
          File Exported
        </h2>

        <Button
          color="black"
          variant="text"
          className="w-100 mb-1"
          onClick={onClose}
        >
          Dismiss
        </Button>
      </form>
    </div>
  );
}

import { useDispatch } from 'react-redux';
import { getProjectResearches, getProjectVariables } from '../api/user';
import {
  setResearchesAction,
  setVariablesAction
} from '../store/reducers/user';

export function useApiCall() {
  return {
    apiGetResearches: useGetResearches(),
    apiGetVariables: useGetVariables()
  };
}

export function useGetResearches() {
  const dispatch = useDispatch();
  const apiCall = async (payload, dp = 1) => {
    const { data } = await getProjectResearches(payload);
    if (dp) dispatch(setResearchesAction(data));
  };
  return apiCall;
}

export function useGetVariables() {
  const dispatch = useDispatch();
  const apiCall = async (payload, dp = 1) => {
    const { data } = await getProjectVariables(payload);
    if (dp) dispatch(setVariablesAction(data));
    return data;
  };
  return apiCall;
}

import * as Yup from 'yup';
import { regex } from '../../constants';

export const data = {
  personalInfo: {
    name: '',
    role: '',
    company: ''
  }
};

export const securityForm = {
  initialValues: {
    current: '',
    repeat: '',
    new: ''
  },
  validationSchema: Yup.object({
    current: Yup.string().required('Password is a required field'),
    new: Yup.string()
      .required('Password is a required field')
      .min(8, 'Password must be at least 8 characters')
      .max(15, 'Password must be at most 15 characters')
      .matches(
        regex.password,
        'Password must contain Uppercase and lowercase letters, numbers, and special characters'
      ),
    repeat: Yup.string()
      .required('Password is a required field')
      .oneOf([Yup.ref('new'), null], "Passwords don't match")
  }),
  validateOnBlur: false,
  validateOnChange: false
};

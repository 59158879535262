import React from 'react';
import { Button } from '@mui/material';

export function InvitationSent({ onSubmit, onClose }) {
  return (
    <div className="main">
      <form className="container w-100">
        <h2 className="fontOutfit title text-center mb-1">
          Invitation(s) sent
        </h2>

        <Button variant="outlined" className="w-100 mb-1" onClick={onSubmit}>
          Invite more
        </Button>

        <Button
          color="black"
          variant="text"
          className="w-100 mb-1"
          onClick={onClose}
        >
          Later
        </Button>
      </form>
    </div>
  );
}

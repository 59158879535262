import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { visuallyHidden } from '@mui/utils';
import { ReactComponent as MoreIcon } from '../assets/svg/Dots.svg';
import { ReactComponent as LayoutColumnIcon } from '../assets/svg/outlined/layout-column-line.svg';
import './enhanced-table.scss';
import { useState } from 'react';
import { DropdownMenu } from './DropdownMenu';
import { formatDate, getComparator, stableSort } from '../utils/utils';
import { Mark, Source, MapImages, MapResearchTags } from './Basic';

function EnhancedTableHead({ headCells, onConfig, ...props }) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts'
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <IconButton onClick={onConfig}>
            <LayoutColumnIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableCell({
  rowId = '',
  children,
  menuItems,
  keyword,
  row,
  ...props
}) {
  function CellDecorator() {
    switch (rowId) {
      case 'comment':
        return <Mark keyword={keyword} text={children} />;
      case 'extract':
        return <Mark keyword={keyword} text={children} images={row.images} type={true} />;
      case 'tags':
        return <MapResearchTags>{children}</MapResearchTags>;
      case 'source':
        return <Source>{children}</Source>;
      case 'images':
        return <MapImages>{children}</MapImages>;
      case 'createdAt':
        return formatDate(children);
      case 'updatedAt':
        return formatDate(children);
      default:
        return children;
    }
  }

  return (
    <TableCell align="left" {...props}>
      {rowId === 'images' ? (
        <div className={`tc-restricted ${rowId} links-word-break`}>
          <CellDecorator />
        </div>
      ) : (
        <div className={`tc-restricted ${rowId}`}>
          <CellDecorator />
        </div>
      )}
    </TableCell>
  );
}

export function EnhancedTable({
  onConfig,
  keyword = '',
  columns = [],
  rows = [],
  menuItems = []
}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Extract');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dense = false;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = rows.map((_, i) => i);
      setSelected(allSelected);
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (event, index) => {
    const selectedIndex = selected.indexOf(index);
    if (selectedIndex === -1) {
      setSelected((prev) => [...prev, index]);
    } else {
      setSelected((prev) => {
        prev.splice(selectedIndex, 1);
        return [...prev];
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (index) => selected.indexOf(index) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const activeCells = [],
    activeKeys = [];

  columns.forEach((col) => {
    if (col.active) {
      activeCells.push(col);
      activeKeys.push(col.id);
    }
  });

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [itemId, setItemId] = useState(null);
  const menuOpen = Boolean(menuAnchor);

  const handleMenuOpen = (e, id) => {
    setMenuAnchor(e.currentTarget);
    setItemId(id);
  };

  const handleMenuClose = () => setMenuAnchor(null);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
            className="enhanced-table"
          >
            <EnhancedTableHead
              headCells={activeCells}
              onConfig={onConfig}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <EnhancedTableCell>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleSelect(event, index)}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </EnhancedTableCell>

                      {activeKeys.map((key) => (
                        <EnhancedTableCell
                          key={key}
                          rowId={key}
                          keyword={keyword}
                          row={row}
                        >
                          {row[key]}
                        </EnhancedTableCell>
                      ))}

                      <TableCell align="left">
                        <div className="tc-restricted">
                          <IconButton
                            onClick={(e) => handleMenuOpen(e, row.id)}
                          >
                            <MoreIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <DropdownMenu
            data={itemId}
            open={menuOpen}
            items={menuItems}
            anchorEl={menuAnchor}
            onClose={handleMenuClose}
          />
        </TableContainer>
        <TablePagination
          page={page}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

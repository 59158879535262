import React from 'react';
import { Button } from '@mui/material';
import classes from './popups.module.scss';

export function ConfirmTagDelete({ onCancel, onConfirm }) {
  return (
    <div className={classes.confirmTagDelete}>
      <h2 className="fontOutfit">Are you sure?</h2>
      <p className="fontOutfit">
        Deleting this tag will remove it from the Research List. This cannot be
        undone.
      </p>
      <div className={classes.buttonGroup}>
        <Button variant="text" color="black" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          className={classes.confirm}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

/* global chrome */
import React, { useEffect, useState } from 'react';
import { Link, Button } from '@mui/material';
import { extensionId } from '../../config';
import { safeCall } from '../../utils/utils';

export function InstallExtension(props) {
  const { setCurrent } = props;
  const logInExtension = 2;
  const [isInstalled, setIsInstalled] = useState(false);
  const secondStep = () => setCurrent(logInExtension);

  function isExtensionInstall() {
    chrome.runtime.sendMessage(
      extensionId,
      { AnalystPro: true },
      function ({ AnalystProInstall }) {
        if (AnalystProInstall) {
          setIsInstalled(true);
        }
      }
    );
  }

  useEffect(() => {
    safeCall(isExtensionInstall);
  });

  const handleGoStore = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/analystpro/gnbadckbnacgkondohadcpejpmefkcee?hl=en&authuser=0'
    );
  };

  return (
    <>
      {isInstalled ? (
        <div className="main centered">
          <div className="container-getting-started">
            <h2 className="second-title text-center mb-1 w-100">
              Congratulations! <br/> (1/4)
            </h2>
            <p className="text text-left mb-1 ml-6">
              You’ve already succesfully installed the browser extension onto
              your Chrome.
            </p>
            <div className="text-center">
              <Button className="w-100" onClick={secondStep}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="main centered">
          <div className="container-getting-started">
            <h2 className="second-title text-center mb-1 w-100">
              Install browser extension (1/4){' '}
            </h2>
            <p className="text text-left mb-1 ml-6">
              Download the AnalystPro{' '}
              <Link underline="hover" className="text" onClick={handleGoStore}>
                browser <br /> extension
              </Link>{' '}
              onto Chrome to use all features AnalystPro has to offer.
            </p>
            <div className="text-center">
              <Button className="w-100 mb-1" onClick={handleGoStore}>
                Install browser extension
              </Button>
              <Button variant="outlined" className="w-100" onClick={secondStep}>
                Already done it!
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

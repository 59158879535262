import React from 'react';
import { Button } from '@mui/material';

export function LogInExtension(props) {
  const { setCurrent } = props;
  const createFirstProject = 3;

  const thirdStep = () => setCurrent(createFirstProject);

  return (
    <div className="main centered">
      <div className="container-getting-started">
        <h2 className="second-title text-center mb-1 w-100">
          Log in browser extension (2/4){' '}
        </h2>
        <p className="text text-left mb-1 ml-6">
          Make sure the AnalystPro browser extension is <a href="https://www.alphr.com/pin-extensions-chrome/">pinned</a>. Log in so you can save research data directly to your dashboard.
        </p>
        <div className="text-center">
          <Button className="w-100 mb-1" onClick={thirdStep}>
            I’m logged in
          </Button>
        </div>
      </div>
    </div>
  );
}

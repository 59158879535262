import React, { useEffect, useState } from 'react';
import classes from '../signIn-signUp/login.module.scss';
import { Button } from '@mui/material';
import { ReactComponent as LogoIcon } from '../../assets/svg/Icon.svg';
import { ReactComponent as AnalystPro } from '../../assets/svg/AnalystPro.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ResetPasswordForm } from '../modals/ResetPasswordForm';
import { ModalProvider } from '../../components/Modal';

export function LandingPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const authorized = useSelector(({ user }) => user.token);
  const resetPasswordToken = params.get('resetPasswordToken');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.title = "App | AnalystPro";
  }, []);
  

  useEffect(() => {
    if (resetPasswordToken) {
      setOpen(true);
    }
  }, [resetPasswordToken]);

  useEffect(() => {
    if (authorized) {
      navigate('/home');
    }
  }, [authorized]);

  function goSignup() {
    navigate('/signup');
  }

  function goSignin() {
    navigate('/signin');
  }

  return (
    <div>
      <header className={classes.appbar}>
        <div className={classes.logo}>
          <LogoIcon />
          <AnalystPro />
        </div>
      </header>
      <div className={classes.center}>
        <div className={classes.main}>
          <div className="container w-100">
            <h2 className="fontOutfit title text-center mb-1">Welcome</h2>
            <div className="fontOutfit text mb-1">
              You must be logged in to use AnalystPro. <br />
              <br />
              Get started now by signing up for free.
            </div>
            <Button className="w-100 mb-1" onClick={goSignup}>
              Sign up
            </Button>
            <Button
              variant="outlined"
              className="w-100 mb-1"
              onClick={goSignin}
            >
              Log in
            </Button>
          </div>
        </div>
      </div>
      <ModalProvider open={open} onClose={handleClose}>
        <ResetPasswordForm token={resetPasswordToken}/>
      </ModalProvider>
    </div>
  );
}

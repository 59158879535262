import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { WelcomePage } from './views/welcome-page/WelcomePage';
import { CreateProject } from './views/modals/CreateProject';
import { ResearchDashboard } from './views/research-dashboard/ResearchDashboard';
import { LandingPage } from './views/landing-page/LandingPage';
import { AppMenu } from './components/AppMenu';
import { HomePage } from './views/homepage/HomePage';
import { Settings } from './views/settings/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { lsGetUser } from './utils/utils';
import {
  logInAction,
  setProjectsAction,
  setUserAction
} from './store/reducers/user';
import { getUserInfo, getUserProjects } from './api/user';
import { SignUpPage } from './views/signIn-signUp/SignUpPage';
import { LogInPage } from './views/signIn-signUp/LogInPage';
import { ResetPasswordPage } from './views/signIn-signUp/ResetPasswordPage';

function MainRoutes() {
  const dispatch = useDispatch();
  useEffect(() => {
    getUserInfo().then((user) => dispatch(setUserAction(user)));
    getUserProjects().then(({ data }) => dispatch(setProjectsAction(data)));
  }, []);

  return (
    <AppMenu>
      <Routes>
        <Route path="/home" element={<HomePage />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/create-project" element={<CreateProject />} />
        <Route path="/getting-started" element={<WelcomePage />} />
        <Route path="/research/:projectId" element={<ResearchDashboard />} />
      </Routes>
    </AppMenu>
  );
}

export function AppRoutes() {
  const location = useLocation();
  console.log('Current location: ' + JSON.stringify(location));
  const dispatch = useDispatch();
  const lsData = lsGetUser();
  if (lsData) dispatch(logInAction(lsData, dispatch));

  const authorized = useSelector(({ user }) => user.token);

  if (authorized) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<MainRoutes />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/signin" element={<LogInPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage/>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }
}

import ReactGA4 from 'react-ga4';

export function initGoogleAnalytics() {
  // Initialize Google Analytics
  console.log('Initialise google analytics');
  ReactGA4.initialize([
    {
      trackingId: `${process.env.REACT_APP_GA4_MANAGEMENT_ID}`,
      gaOptions: { debug: true }
    }
  ]);
}

export function googleIdentifyUser(user) {
  ReactGA4.set({
    userId: user.id
  });
}

export function setPageChanges(pathname){
  ReactGA4.set({ page: pathname });
  ReactGA4.send({ hitType: 'pageview', page: pathname });
}

export function sendEvent(analyticsOptions) {
  ReactGA4.event(analyticsOptions);
}

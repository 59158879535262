import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function idProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const tabStyles = {
  tab: {},
  active: {},
  indicator: {}
};

export function TabProvider({ tabs, value, onChange, sx = tabStyles }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={onChange}>
          {tabs.map((tab, key) => (
            <Tab key={key} label={tab.label} {...idProps(key)} sx={sx.tab} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, key) => (
        <TabPanel key={key} value={value} index={key}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
}

import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';

export function DropdownMenu({ open, items, anchorEl, onClose, data }) {
  const handleClick = (callback) => {
    !data ? callback(data) : callback(data);
    return onClose();
  };
  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl} elevation={1}>
      {items.map((item, key) => (
        <MenuItem onClick={() => handleClick(item.onClick)} key={key}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText>
            <div className="text-small">{item.label}</div>
          </ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
}

import { env as config } from '../config';
import { userConfig } from '../config';
import moment from 'moment';

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function safeCall(callback) {
  try {
    return callback();
  } catch (e) {
    console.error('Callback failed: ' + e);
    return null;
  }
}

export function formatDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function lsSetUser(payload) {
  localStorage.setItem(
    config.lsTokenKey,
    safeCall(() => {
      return JSON.stringify(payload);
    })
  );
}

export function lsGetUser() {
  return safeCall(() => {
    return JSON.parse(localStorage.getItem(config.lsTokenKey));
  });
}

export function lsWipeData() {
  localStorage.removeItem(config.lsTokenKey);
  localStorage.removeItem(userConfig.lsConfigKey);
}

export function hasValues(obj) {
  // check if all props have values
  for (const key in obj) {
    if (!obj[key]) return false;
  }
  return true;
}

export function mapEmpty(obj) {
  // removes "" & nulls from obj
  const newObject = { ...obj };
  for (const key in newObject) {
    if (!newObject[key]) {
      newObject[key] = undefined;
    }
  }
  return newObject;
}

export function containsValue(obj) {
  // check if at least one prop contains value
  for (const key in obj) {
    if (obj[key]) {
      return true;
    }
  }
  return false;
}

export function getProjectName(name) {
  return name[0].toUpperCase();
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

export function lsGetUserConfig() {
  return safeCall(() => {
    const savedConfig = localStorage.getItem(userConfig.lsConfigKey);
    return JSON.parse(savedConfig);
  });
}

export function lsSetUserConfig(payload) {
  return safeCall(() => {
    const newConfig = JSON.stringify(payload);
    return localStorage.setItem(userConfig.lsConfigKey, newConfig);
  });
}

export function lsUpdateUser(payload) {
  const prev = lsGetUser();
  lsSetUser({
    ...prev,
    ...payload
  });
}

export function lsUpdateUserConfig(payload) {
  const prev = lsGetUserConfig();
  lsSetUserConfig({
    ...prev,
    ...payload
  });
}

export function getObjectKey(object, value) {
  for (const key in object) {
    if (object[key] === value) return key;
  }
  return null;
}

export function composeQuery(object) {
  let queryString = '';
  for (let key in object) {
    const char = !queryString ? '?' : '&';
    let value = object[key];
    if (Array.isArray(value)) {
      value.forEach(
        (v) => (queryString += char + key + '[]=' + encodeURIComponent(v))
      );
      continue;
    }
    queryString += char + key + '=' + encodeURIComponent(value);
  }
  return queryString;
}

export function mapFilterValues(key, value) {
  if (key === 'tags') return [value];
  if (key === 'createdAt' || key === 'updatedAt') return Date.parse(value);
  return value;
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function openNewTab(url) {
  window.open(url, '_blank');
}

export const isWhite = (color) => ['white', '#fff', '#ffffff'].includes(color);

export const objectsEqual = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

import React, { useState } from 'react';
import classes from './research-dashboard.module.scss';
import { ReactComponent as CloseIconSvg } from '../../assets/svg/CloseIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectsAction } from '../../store/reducers/user';
import { useParams } from 'react-router-dom';
import { notify } from '../../components/Snackbar';
import { hasValues } from '../../utils/utils';
import { createProjectTag, deleteTag, updateTag } from '../../api/user';
import { tagColors } from './module';
import { ModalComponents, useModalComponents } from '../modals/ModalComponents';
import { isWhite } from '../../utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  TextField,
  Select,
  MenuItem,
  Button,
  Chip
} from '@mui/material';
import { useApiCall } from '../../hooks/useApiCall';

export default function TagManager({ onClose }) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const userProjects = useSelector(({ user }) => user.projects);
  const currentProject = userProjects.find((p) => p.id == projectId);
  const projectTags = currentProject.tags;

  const initialState = {
    name: '',
    color: tagColors[0].value
  };

  const [newTag, setNewTag] = useState(initialState);

  const handleTagChange = (e) => {
    const { value, name } = e.target;
    setNewTag((prev) => ({ ...prev, [name]: value }));
  };

  const handleTagUpdate = (id, form) => {
    updateTag(id, form)
      .then((tag) => {
        const tid = projectTags.findIndex((t) => t.id === tag.id);
        projectTags[tid] = { ...projectTags[tid], ...tag };
        dispatch(setProjectsAction(userProjects));
        return apiGetResearches({ projectId });
      })
      .then(handleModalClose);
  };

  const handleChipClick = (tag) => {
    openModal(modals.updateTag, {
      onSubmit: (form) => handleTagUpdate(tag.id, form),
      onCancel: handleModalClose,
      projectId: projectId,
      tag: tag
    });
  };

  function handleCreateTag() {
    if (!hasValues(newTag)) return notify.warning('All fields are required!');
    createProjectTag({
      ...newTag,
      projectId
    })
      .then((tag) => {
        currentProject.tags = [...currentProject.tags, tag];
        dispatch(setProjectsAction(userProjects));
        setNewTag(initialState);
      })
      .catch((e) => notify.error('Failed to add!', e));
  }

  const { modal, modalOpen, modals, openModal, handleModalClose } =
    useModalComponents();

  const { apiGetResearches } = useApiCall();
  const handleTagDelete = (id) => {
    deleteTag(id)
      .then(() => {
        currentProject.tags = currentProject.tags.filter((t) => t.id !== id);
        dispatch(setProjectsAction(userProjects));
        return apiGetResearches({ projectId });
      })
      .catch((e) => notify.error('Failed to delete!', e));
    handleModalClose();
  };

  return (
    <div className={classes.drawerMain}>
      <div className={classes.drawerHeader}>
        <div className={`fontOutfit ${classes.headerText}`}>Manage tags</div>

        <div className={classes.icon} onClick={onClose}>
          <CloseIconSvg />
        </div>
      </div>

      <div className={classes.drawerBody}>
        <Divider />
        <div className={classes.textBlock}>
          <h5 className="fontOutfit">Add new tag</h5>
        </div>

        <div>
          <div className={classes.fields}>
            <TextField
              className="w-100"
              placeholder="Type new tag"
              name="name"
              value={newTag.name}
              onChange={handleTagChange}
            />
          </div>

          <div className={classes.fields}>
            <Select
              displayEmpty
              className="w-100"
              placeholder="Select color"
              name="color"
              value={newTag.color}
              onChange={handleTagChange}
            >
              {tagColors.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Button
            disabled={!newTag.name}
            className={classes.buttonCreateTag}
            onClick={handleCreateTag}
          >
            Create Tag
          </Button>
        </div>
        <div className={classes.allTags}>
          <div className={classes.textBlock}>
            <h5 className="fontOutfit">All tags</h5>
          </div>
          <div className={classes.listButtons}>
            {projectTags.map((tag, key) => {
              return (
                <Chip
                  key={key}
                  className={classes.tagButton}
                  sx={{
                    bgcolor: tag.color,
                    color: isWhite(tag.color) ? '#000' : '#fff'
                  }}
                  label={tag.name}
                  deleteIcon={<CloseIcon />}
                  onClick={() => handleChipClick(tag)}
                  onDelete={() =>
                    openModal(modals.confirmTagDelete, {
                      onCancel: handleModalClose,
                      onConfirm: () => handleTagDelete(tag.id)
                    })
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={classes.drawerFooter} />
      <ModalComponents
        modal={modal}
        open={modalOpen}
        onClose={handleModalClose}
      />
    </div>
  );
}

import React, { useEffect } from 'react';
import classes from './homepage.module.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getProjectName, stringToColor } from '../../utils/utils';
import { StartResearch } from './StartResearch';

export function HomePage() {
  const navigate = useNavigate();
  const projects = useSelector(({ user }) => user.projects) || [];

  useEffect(() => {
    document.title = "Home | AnalystPro";
  }, []);

  return (
    <>
      {projects.length ? (
        <div className={classes.main}>
          <h2 className="fontOutfit">My dashboard</h2>
          <div className={classes.container}>
            <div className={classes.projects}>
              <h3 className="fontOutfit">Projects</h3>
              <div className={classes.items}>
                {projects.map((project, key) => {
                  const backgroundColor = stringToColor(project.name),
                    shortName = getProjectName(project.name);
                  return (
                    <div
                      key={key}
                      className={classes.project}
                      onClick={() => {
                        navigate('/research/' + project.id);
                      }}
                    >
                      <div className={classes.content}>
                        <div
                          className={`fontOutfit ${classes.circle}`}
                          style={{ backgroundColor }}
                        >
                          {shortName}
                        </div>
                        <div className={`fontOutfit ${classes.title}`}>
                          {project.name}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <StartResearch />
      )}
    </>
  );
}

import React, { useState } from 'react';
import classes from './add-notes.module.scss';
import { Button, Collapse, MenuItem, TextField } from '@mui/material';
import { tooltips } from './module';
import { ReactComponent as InfoIcon } from '../../assets/svg/InfoIcon.svg';
import { BootstrapTooltip } from '../../components/BootstrapTooltip';
import { dataTypes } from '../research-dashboard/module';
import { CustomSelect } from '../../components/CustomFields';
import {
  containsValue,
  hasValues,
  mapEmpty,
  objectsEqual
} from '../../utils/utils';
import { notify } from '../../components/Snackbar';
import { createProjectVariable, updateUserResearch } from '../../api/user';
import {
  updateResearchAction,
  updateVariablesAction
} from '../../store/reducers/user';
import { useDispatch } from 'react-redux';

export function AddNotes({ title = 'Edit notes', onSave, onCreate, data }) {
  const dispatch = useDispatch();
  const { tags, researches, researchId, projectId } = data;
  const research = researches.find((r) => r.id === researchId) || {};
  const tagIds = research.tags.map((t) => t.id);
  const initialForm = { comment: research.comment, tagIds };
  const initialVariable = {
    name: '',
    value: '',
    dataType: ''
  };

  const [form, setForm] = useState(initialForm);
  const [variable, setVariable] = useState(initialVariable);
  const [createVariable, setCreateVariable] = useState(false);
  const updateForm = !objectsEqual(form, initialForm);

  const handleCreateClick = () => {
    setCreateVariable((prev) => !prev);
  };

  const handleSaveClick = () => {
    if (updateForm) {
      if (!containsValue(form))
        return notify.warning('Provide comment or tag to edit!');
      const tagValues = form.tagIds === tagIds ? undefined : form.tagIds;
      updateUserResearch(
        researchId,
        mapEmpty({ ...form, tagIds: tagValues })
      ).then((research) => {
        dispatch(updateResearchAction(research));
      });
    }

    if (createVariable) {
      if (hasValues(variable)) {
        createProjectVariable({
          ...variable,
          projectId
        }).then((data) => {
          dispatch(updateVariablesAction(data));
        });
      }
    }

    onSave();
  };

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleVariableChange = (e) => {
    const { value, name } = e.target;
    setVariable((prev) => ({ ...prev, [name]: value }));
  };

  const commonProps = {
    className: classes.field,
    bg: 'gray',
    size: 'xs'
  };

  const getFormProps = (name) => ({
    ...commonProps,
    name: name,
    value: form[name],
    onChange: handleFormChange
  });

  const getVariableProps = (name) => ({
    ...commonProps,
    name: name,
    value: variable[name],
    onChange: handleVariableChange
  });

  const handleMultipleSelect = (e) => {
    const { value } = e.target;
    setForm((prev) => ({ ...prev, tagIds: value }));
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{title}</div>
        </div>
        <div className={classes.body}>
          <div className={`${classes.item} ${classes.comment}`}>
            <div className={classes.label}>Comment</div>
            <TextField
              placeholder="Enter comment"
              {...getFormProps('comment')}
            />
          </div>
          <div className={classes.item}>
            <div className={classes.label}>Tag</div>
            <CustomSelect
              multiple
              placeholder="Select tag"
              {...getFormProps('tagIds')}
              onChange={handleMultipleSelect}
            >
              {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
              <MenuItem value="" onClick={onCreate}>
                Create new tag
              </MenuItem>
            </CustomSelect>
          </div>

          <Collapse in={createVariable} className={classes.collapse}>
            <div className={classes.title}>Create value</div>
            <div className={classes.variable}>
              <div className={classes.item}>
                <div className={classes.label}>
                  <div>Name</div>
                  <BootstrapTooltip title={tooltips.sourceExtract}>
                    <InfoIcon />
                  </BootstrapTooltip>
                </div>
                <TextField
                  placeholder="Enter value name"
                  {...getVariableProps('name')}
                />
              </div>
              <div className={classes.item}>
                <div className={classes.label}>
                  <div>Data type</div>
                  <BootstrapTooltip title={tooltips.dataType}>
                    <InfoIcon />
                  </BootstrapTooltip>
                </div>
                <CustomSelect
                  placeholder="Select data type"
                  {...getVariableProps('dataType')}
                >
                  {dataTypes.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
              <div className={classes.item}>
                <div className={classes.label}>
                  <div>Value</div>
                  <BootstrapTooltip title={tooltips.variableValue}>
                    <InfoIcon />
                  </BootstrapTooltip>
                </div>
                <TextField
                  placeholder="Enter value"
                  {...getVariableProps('value')}
                />
              </div>
            </div>
          </Collapse>
        </div>
        <div className={classes.footer}>
          <Button
            disableElevation
            variant="text"
            className={classes.create}
            onClick={handleCreateClick}
          >
            {!createVariable ? '+ Create value' : '- Remove value'}
          </Button>
          <Button
            disableElevation
            className={classes.save}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

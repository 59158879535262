import React from 'react';
import { Button } from '@mui/material';
import { AnalyticsEvent } from '../../utils/analytics';
import { openNewTab } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

export function StartResearch() {
  const navigate = useNavigate();

  const toCreateProject = () => navigate('/create-project');

  function handleGuideClick() {
    openNewTab('https://vimeo.com/733680567');
    toCreateProject();
  }

  const showMeHowClick = AnalyticsEvent(
    {
      category: 'user_click',
      action: 'show_me_how_button_click'
    },
    handleGuideClick
  );

  return (
    <div className="main centered">
      <div className="container">
        <h2 className="fontOutfit title text-center mb-1">Start research</h2>
        <p className="fontOutfit text mb-1">
          Your research project looks empty.
          <br />
          <br />
          Your browser extension is linked and your project is set up, let’s
          start researching!
        </p>
        <Button onClick={showMeHowClick} className="w-100 mb-1">
          Show me how
        </Button>
        <Button
          onClick={toCreateProject}
          className="w-100 mb-1"
          variant="outlined"
        >
          I'll figure it out
        </Button>
      </div>
    </div>
  );
}
